import store from '@/store';
import Form from '@/services/form';

import {
  required,
  email,
} from '@vuelidate/validators';


const data = store.getters['auth/profile'];

const businessRules = store.getters['auth/isBusinessUser']
  ? { business_name: { required } }
  : {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
    };

const rules = {
  email: {
    required,
    email,
  },
  phone: {
    required,
  },
  ...businessRules
}

export default () => new Form(data, rules, 'auth/updateProfile');
