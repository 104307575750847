import Form from '@/services/form';

import {
  required,
  minLength,
} from '@vuelidate/validators';


const data = {
  password_current: null,
  password: null,
  password_confirmation: null,
}

const rules = {
  password_current: {
    required,
  },
  password: {
    required,
    minLength: minLength(6),
  },
  password_confirmation: {
    required,
    dynamicValidators: [{
      type: 'sameAs',
      field: 'password',
      params: ['password'],
    }],
  },
}

export default () => new Form(data, rules, 'auth/changeCurrentPassword');
