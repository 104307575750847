import {
  About,
  Test
} from '@/views/pages';

export default [
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      authOnly: true,
      transition: 'fade'
    }, //need auth token and verify
  },

  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
];
