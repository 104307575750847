export default {
  mounted(el) {
    if (['input', 'button'].includes(el.tagName.toLowerCase())) {
      el.focus();
    } else {
      const nodes = el.querySelectorAll('input, button');

      if (nodes.length) {
        nodes[0].focus();
      }
    }
  }
}
