import {createStore} from 'vuex'
import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import plugins from './plugins';

import {
  auth,
  cards,
  favorites,
  orders,
  business,
} from './modules';

export default createStore({
  state,
  mutations,
  actions,
  getters,
  plugins,

  modules: {
    auth,
    cards,
    favorites,
    orders,
    business,
  },
})
