export default {
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },

  setCityId(state, payload) {
    state.cityId = payload;
  },

  setLocation(state, payload) {
    state.location = payload;
  },

  setStateActiveMenu(state, payload){
    state.isActiveMenu = payload;
  },

  setMapKey(state, payload){
    state.mapKey = payload;
  }
}
