<template>
  <div class="card mb-3">
    <div class="card-body">

      <h6 class="card-title">
        <img v-if="type=='pickup'"
             src="@/assets/images/icons/checkbox-circle.svg"
             alt=""
             class="pe-2"
        />

        <img v-if="type=='dropoff'"
             src="@/assets/images/icons/checkbox.svg"
             alt=""
             class="pe-2"
        />
        <span class="text-muted ">
          {{ caption }}
        </span>
      </h6>
      <span class="d-block text-muted pt-2 pb-2">Address</span>
      <p class="card-text text-style">
        {{ text }}
      </p>

    </div>
  </div>
</template>

<script>
export default {
  name: "LabelAddress",
  props: ['type' , 'caption' , 'text']
}
</script>

<style scoped>
.text-style{
  font-family: BariolBold;
  font-size: 14px;
  color: #457C9F;


}
</style>