import {
  ForgotPasswordVerification,
  SignupVerification,
} from '@/views/pages/otp';

export default [
  {
    path: '/forgot-password-verification',
    name: 'ForgotPasswordVerification',
    component: ForgotPasswordVerification,
    meta: {
      guestOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/signup-verification',
    name: 'SignupVerification',
    component: SignupVerification,
    meta: {
      guestOnly: true,
      transition: 'fade'
    }
  },
];
