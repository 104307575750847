<template>
  <div class="form-floating">
    <VueTelInput
        :value="telValue"
        :inputOptions="options"
        :autoDefaultCountry="autoCountry"
        @input="updateValue"
    />

    <InputError
        :form="form"
        :fieldVuelidate="v$"
        :errors="e$"
        :field="field"
        :name="name$"
        :show-no-error="showNoError"
    />
  </div>
</template>

<script>
import InputError from './InputError.vue';
import {inputProps, inputComputed} from './InputAttributes';
import {VueTelInput} from 'vue3-tel-input';

export default {
  name: 'TelInput',

  data() {
    return {
      tel: null,
      defaultCountry: '',
    }
  },

  components: {
    InputError,
    VueTelInput,
  },

  props: {
    ...inputProps,
    autoCountry: {
      type: Boolean,
      default: true
    },
    showNoError: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    ...inputComputed,

    telValue() {
      return this.tel || this.value;
    },

    options() {
      return {
        placeholder: this.placeholder$,
      }
    },
  },

  methods: {
    updateValue(number, tel) {
      if (!tel || !this.value && !number) {
        return;
      }

      this.form.set(this.field, tel.number);
      this.form.validator.tel = tel;
      this.tel = number;

      if (this.v$) {
        this.v$.$touch();

        this.e$.clear(this.field);
      }
      if (this.v$.$error){
        this.form.set(this.field, tel.formatted);
      }
    },
  }

}
</script>

<style scoped>

@import '~vue3-tel-input/dist/vue3-tel-input.css';

.form-floating-peyk .vti__dropdown {
  justify-content: flex-end !important;
}

.form-floating-peyk input.vti__inpupt {
  border: none !important;
}

</style>
