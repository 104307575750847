export const toaster = () => window.$app.$toast;

export const showMessage = (data) => {
  if (data.status === "success") {
    toaster().success(data.message);
  } else {
    const errorlist = data.data.errors;
    if (errorlist === undefined) {
      toaster().error(data.message);
    } else {
      Object.keys(errorlist).forEach((key) => {
        let caption = key.replace(".0.", " pickup ");
        caption = caption.replace(".1.", " dropoff ");
        toaster().error(
          "&#8226; " + caption + ":<br />" + errorlist[key].join("<br />")
        );
      });
    }
  }
};

export const showErrorIfExists = (data) => {
  if (data.status !== "success") {
    toaster().error(data.message);
  }
};

export const isSuccessful = (data) => data.status === "success";

export const getPositionObject = (locationObject) => {
  const { lat, long } = locationObject;
  return {
    lat: parseFloat(lat),
    lng: parseFloat(long),
  };
};

export const getPositionString = (locationString) => {
  const location = locationString.split(",");
  return {
    lat: parseFloat(location[0]),
    lng: parseFloat(location[1]),
  };
};
