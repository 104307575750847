import Form from "@/services/form";

import {
    required,
    minLength,
    numeric
} from "@vuelidate/validators";

const data = {
    building_no: null,
    zone_no: null,
    street_no: null,

    type: null,
    lat: null,
    long: null,
    name: null,
    phone: null,
    postcode: null,
    address: null,
    description: null,
    'extra-pay': null,
};

const rules = {
    name: {
        required,
        minLength: minLength(3),
    },
    address: {
        // required,
        // minLength: minLength(3),
        // maxLength: maxLength(200),
    },
    lat: {
        required
    },
    long: {
        required
    },
    phone: {
        required,
        tel: null,
    },
    'extra-pay': {
        numeric,
    }
};

export default () => new Form(data, rules);