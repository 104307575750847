export const inputProps = {
  form: Object,
  vuelidate: Object,
  field: String,
  caption:String,
  name: String,
  placeholder: String,
  type: {
    type: String,
    default: 'text',
  },
}

export const inputComputed = {
  value() {
    return this.form.get(this.field);
  },

  placeholder$() {
    return this.placeholder || this.name$;
  },

  caption$(){
    return this.caption;
  },

  name$() {
    return this.name || this.field.charAt(0).toUpperCase() + this.field.slice(1);
  },

  v$() {
    return this.vuelidate[this.field];
  },

  e$() {
    return this.form.validator.errors;
  },
}
