import Form from "@/services/form";

const data = {
    pointOne: null,
    pointTwo: null,
    vehicle_id: null,
};

const rules = {};

export default () => new Form(data, rules, "orders/getOrderDuration");
