<template>
<div class="col-md-6 col-lg-4 mt-4">
    <div class="card box ">
        <div class="card-body">
            <div class="card-title d-flex justify-content-between align-items-center">
                <p class="id-title-order mb-0">#{{ value.id }}Pass</p>
              <p class="price-title-order mb-0">QAR{{ value.user_price }}</p>
            </div>
            <hr class="peyk-hr-style">
            <div class="row mt-3">
                <div class="col-1 d-table my-auto">
                  <span class="new-circle"></span>
                </div>
                <div class="col-9 col-md-10 d-table my-auto">
                    <p class="order-pickup  mb-0">Pickup</p>
                    <p class="order-pickup-address">{{value.sender_address}}</p>
                </div>
              <template v-for="dropoff in value?.receiver_info" :key="dropoff.address">
                <div>
                  <hr class="order-split">
                </div>

                <div class="col-1 mt-3" style="z-index: 1;">
                  <span class="new-square"></span>
                </div>
                <div class="col-10 col-md-10 d-table my-auto">
                  <p class="order-dropof mb-0">Dropoff</p>
                  <p class="order-dropof-address">{{dropoff.address}}</p>
                </div>
              </template>
              <hr class="header-divider">
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-3">
                    <p class="order-sectio3-box">Return: <span class="order-sectio3-box-span ps-1">{{value.return_trip ? "Yes" : "No"}}</span></p>
                </div>
                <div class="col-9">
                    <p class="order-sectio3-box">Order Status:<span class="order-sectio3-box-span ps-1">{{ value.OrderStatus }}</span></p>
                </div>
                <div class="col-12 ">
                    <p class="order-sectio3-box">Note For driver : <span class="text-info"><br>
                      {{ value?.receiver_info[0].description }}</span>
                    </p>
                </div>
                <div class="col-12 ">
                    <p class="order-sectio3-box">Schedule : <span class="text-info"> {{ value?.schedule ? value?.schedule : 'immediate' }}</span> </p>
                </div>
            </div>
            <hr class="header-divider">
            <div class="footer-section">
              <!-- <div class="driver-section">
                <img :src="driverAvatar" class="driver-img" alt="driver-img">
                <div class="driver-text-section">
                  <span class="driver-name">{{  value?.driver_information?.first_name ? value?.driver_information?.first_name + ' '+ value?.driver_information?.last_name : "Not Assigned Yet"}}</span>
                  <span class="driver-type">Driver </span>
                </div>
              </div> -->
              <div class="button-section">
                <button class="details-button" @click="goOrderDetail(value.id)">
                  Order Details
                </button>
                <!-- <button class="cancel-button" @click="goOrderDetail(value.id)"> -->
                <button class="cancel-button" @click="cancelOrder(value.id)">
                  Cancel
                </button>
              </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState ,mapGetters} from 'vuex';
import { mapMutations } from 'vuex';
import {toaster} from "@/common/helpers";
import {orders } from '@/api';
export default {
  name: "CurrentOrder",
  props: ['value'],
  methods: {
    goOrderDetail(orderID) {
      // this.$router.push({name: 'OrderTracking', params: { id: orderID}});
      this.$router.push({name: 'OrderDetails', params: { id: orderID}});
    },
    async cancelOrder(orderID) {
      if (confirm('Are you sure you want to cancel order ?')) {
        let result = await orders.orderCancel({order_id:orderID});
        if(result?.data?.status)
        {
          toaster().success(result?.data?.message);
          this.removeOrder(orderID);
        }else{
          toaster().error(result?.data?.message);
        }
        // clearInterval(this.orderStatusInterval); // clear call request in the all project
        this.setLastOrderId(0);
        // this.showRequestBox = false;
        // window.location.reload()
      }
    },
    ...mapMutations('orders', ['setLastOrderId' , 'removeOrder']),
  },
  
  computed: {
    ...mapState(["isLoading", "cityId"]),
    ...mapState("orders", ["clientSecret", "lastOrderId", "duration"]),
    ...mapState('cards' , ['balance']),
    ...mapGetters('auth' , ['fullname' , 'mobile', 'isBusinessUser']),
    ...mapGetters('orders' , ['vehiclePrice']),
    
    driverAvatar() {
      return this.value?.driver_information?.avatar != "" ? this.value?.driver_information?.avatar : require('@/assets/images/icons/driver-avatar.png');
    }
  },
}
</script>

<style>
.peyk-driver-info{
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.box{
  display: flex;
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 2px;
  margin: 1rem;
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border:none !important;
}

.box:hover{
  background: #fafafa;
  box-shadow: 0 10px 20px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
}
.new-circle{
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: #00B7C2;
}
.new-square{
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 10px;
  background: #00204A;
}
.footer-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 22px;
}
.driver-section{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.driver-text-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2px;
}
.button-section{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 23px;
}
.driver-img{
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin-right: 20px;
}
.driver-name{
  font-family: 'Nunito', sans-serif;
  font-weight: normal;
  font-size: 24px;
  color: #00204A;
  margin-bottom: 7px;
}
.driver-type{
  margin: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #BCBCBC;
}
.details-button, .cancel-button{
  flex: 1;
  border-radius: 6px;
  padding: 11px 0;
  background: #00B7C2;
  color: #FFFFFF;
  border: none;
  outline: none;
}
.cancel-button{
  background: #E9E9E9;
  color: #00204A;
}
</style>
