export const geocode = async (address) => {
  const geocoder = new window.google.maps.Geocoder();
  const result = await geocoder.geocode({ address });

  return result;
};

export const reverseGeocode = async (lat, lng) => {
  const geocoder = new window.google.maps.Geocoder();
  const { results } = await geocoder.geocode({ location: { lat, lng } });

  const { formatted_address, address_components } = results[0];

  return {
    address: formatted_address,
    street: address_components.find((component) =>
      component.types.includes("route")
    )?.long_name,
    // street_number: address_components.find((component) => component.types.includes('street_number'))?.long_name,
    postal_code: address_components.find((component) =>
      component.types.includes("postal_code")
    )?.long_name,
  };
};
