<template>
  <div class="container-fluid">
    <div class="row  set-max-height">

      <!-- ****************** left side bar ********************* -->
      <div class="col-md-6 order-md-first ">
        <div class="row ">
          <div class="col-md-8 offset-md-2 col-10 offset-1">

            <div class="header-form header-form-peyk">
              <img src="@/assets/images/icons/logo.png" class="mt-5" width="100">
              <h1 class="mt-5 pt-5">Verification Code</h1>
              <p class="my-3">Enter your verification code</p>
            </div>
            <div class="body-form mb-5 pb-5 mt-4">
              <div class="mb-3">
                <div class="row">
                  <div class="col-12 text-center p-0">
                    <h3 class="text-center text-muted"> {{ mobile }}</h3>
                  </div>
                </div>
              </div>

              <form class="row mb-3" @submit.prevent="submit">
                <MaskInput class="col-12 p-0 mb-3"
                  v-focus
                  :form="form"
                  :vuelidate="v$"
                  field="verification_code"
                  name="Verification Code"
                />

                <div class="link-login-signup text-center mb-3">

                    <template v-if="countDownHandle">
                      <div class="countdown">{{ countDownText }}</div>
                    </template>

                    <template v-else>
                      <a href="#" @click.prevent="resendCode">Resend Code</a>
                    </template>
                </div>

                <SubmitButton class="col-12"
                  :form="form"
                  :vuelidate="v$"
                />
              </form>
            </div>

          </div>
        </div>
      </div>

      <!-- ****************** right side bar ********************* -->
      <div class="col-md-6 px-0 order-md-first order-first d-none d-sm-none d-md-block d-lg-block right-side">
        <figure class="verification-page border-position-pics"></figure>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Verification } from '@/forms/signup';
import { MaskInput, SubmitButton } from '@/components/forms';

export default {
  name: 'SignupVerification',

  components: {
    MaskInput,
    SubmitButton,
  },

  data() {
    return {
      countDownHandle: null,
      counter: 0,
    }
  },

  computed: {
    ...mapState('auth', ['registrationData']),

    mobile() {
      return this.registrationData.mobile;
    },

    countDownText() {
      const minutes = Math.floor(this.counter / 60);
      const seconds = this.counter % 60;

      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  },

  setup() {
    const form = Verification();
    const { v$ } = form;

    return {
      form,
      v$,
    }
  },

  mounted() {
    this.enableCountDown();
  },

  methods: {
    ...mapActions('auth', ['resendRegisterVerificationCode']),

    async submit() {
      if (! this.v$.$invalid) {
        for (const field in this.registrationData){
          this.form.set(field, this.registrationData[field]);
        }
        const status = await this.form.submit();

        if (status) {
          this.$router.push({ name: 'NewOrder' });
        }
      }
    },

    resendCode() {
      const { mobile } = this;

      this.resendRegisterVerificationCode({ mobile });

      this.enableCountDown();
    },

    enableCountDown() {
      this.counter = 60;

      this.countDownHandle = setInterval(() => {
        this.counter--;

        if (! this.counter) {
          this.disableCountDown();
        }
      }, 1000);
    },

    disableCountDown() {
      clearInterval(this.countDownHandle);

      this.countDownHandle = null;
      this.counter = 0;
    }
  },

  unmounted() {
    this.disableCountDown();
  },
}
</script>
