import Api from "./api.js";

export default {
  login(payload) {
    return Api().post("v1/user/login", payload);
  },

  logout() {
    return Api().post("v1/user/logout");
  },

  register(payload) {
    return Api().post("v1/user/register", payload);
  },

  resendRegisterVerificationCode(payload) {
    return Api().post("v1/user/try-verification-send", payload);
  },

  verifyRegistrationMobile(payload) {
    return Api().post("v1/user/verify-mobile", payload);
  },

  // step :1
  forgotPassword(payload) {
    return Api().post("v1/user/forget-password", payload);
  },

  //step :2
  verifyForgotPasswordMobile(payload) {
    return Api().post("v3/user/forget-check-verify-code", payload);
  },

  //step : 3
  resetPassword(payload) {
    return Api().post("v3/user/forget-change-password", payload);
  },

  //free step :
  retryForgotPassword(payload) {
    return Api().post("v1/user/forget-sms-again", payload);
  },

  //user profile api
  changeProfile(payload) {
    return Api().post("v1/user/change-profile", payload);
  },

  changeCurrentPassword(payload) {
    return Api().post("v1/user/change-current-password", payload);
  },
};
