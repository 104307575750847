import Api from "./api";

export default {
  getOrderPrice(payload) {
    if (!payload.vehicle_id) {
      delete payload.isBusinessUser;
    }
    // return Api().post(payload.isBusinessUser ? "v1/user/orderPriceNew" : "v2.5/user/order-price", payload);
    return Api().post(
      payload.isBusinessUser
        ? "v1/user/centralizedApi/order-price"
        : "v1/user/centralizedApi/order-price",
      payload
    );
  },

  getOrderDuration(payload) {
    // return Api().post("v2.5/user/order-duration", payload);
    return Api().post("v1/user/centralizedApi/order-duration", payload);
  },

  getCurrentOrders() {
    return Api().post("v1/user/current-orders");
  },

  getDiscountList() {
    return Api().get("v3/user/discount-list");
  },

  downloadInvoice(id) {
    return Api().post(
      "v1/user/order-invoice",
      {
        id,
      },
      {
        responseType: "blob",
      }
    );
  },

  downloadSampleFile() {
    return Api().get("v3/user/order-download-sample", {
      responseType: "blob",
    });
  },

  downloadTemplateFile() {
    return Api().get("v3/user/order-download-excel", {
      responseType: "blob",
    });
  },

  uploadExcel(payload) {
    return Api().post("v3/user/order-upload-excel", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  acceptExcel() {
    return Api().get("v3/user/order-accept-excel", {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getOrders(page) {
    return Api().post(`v1/user/order-list?page=${page}&perpage=9`);
  },

  orderSubmit(payload) {
    // return Api().post("v2.5/user/order-submit", payload);
    return Api().post("v1/user/centralizedApi/order-submit", payload);
  },

  orderCancel(payload) {
    // return Api().post("v1/user/order-cancel", payload);
    return Api().post("v1/user/centralizedApi/order-cancel", payload);
  },

  orderStatus(payload) {
    // return Api().post("v1/user/order-status", payload);
    return Api().post("v1/user/centralizedApi/order-status", payload);
  },

  orderCancelWithReason(payload) {
    return Api().post("v1/user/cancel-order-with-reason", payload);
  },

  getOrderDetail(payload) {
    return Api().post("v3/user/order-detail", payload);
  },

  driverScore(payload) {
    return Api().post("v1/user/driver-score", payload);
  },

  report(payload) {
    return Api().post("v1/user/report", payload);
  },
};
