<template>
<div class="row mt-3">
    <div class="col-12">
        <button type="button" class="add-new-dropoff text-start">
            <img src="@/assets/images/icons/plus.png" class="pe-3">
            <span>Add New Drop off</span>
        </button>
    </div>
    <hr class="mt-3">
</div>
</template>

<script>
export default {
    name: "AddNewDropoffButton",
    props: ["addnewdropoffbutton"],
};
</script>
