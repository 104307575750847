<template>
  <div class="h-100">
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { googleMap } from '@/configs';
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    settings: {
      type: Object,
      default: googleMap.settings,
    },

    center: {
      type: Object,
      default: googleMap.settings.center,
    },

    centerMarkerEnabled: {
      type: Boolean,
      default: false,
    },

    geolocationEnabled: {
      type: Boolean,
      default: false,
    },

    centerMarker: {
      type: String,
      default: 'default-marker',
    },

    markers: {
      type: Array,
      default: () => [],
    },

    bounds: {
      type: Array,
      default: () => [],
    },

    path: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      google: null,
      map: null,
      directionsRenderer: null,
    }
  },

  async mounted() {
    if (this.mapKey != "") {
      const googleMapApi = await new Loader({
        apiKey: this.mapKey,
        version: "weekly",
        libraries: ['places'],
      }).load();

      this.google = googleMapApi;
      this.geocoder = new this.google.maps.Geocoder();
      this.geocoderOK = this.google.maps.GeocoderStatus.OK;
      this.directionsRenderer = new this.google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#00B7C2',
          strokeOpacity: 0,
          strokeWeight: 2,
          icons: [
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeOpacity: 1,
                scale: 4,
              },
              offset: "0",
              repeat: "20px",
            },
          ]
        }
      });
      this.initializeMap();
      this.centerChanged();
    }
  },

  computed: {
    mapSettings() {
      let settings = { ...this.settings };

      return settings;
    },
    ...mapState(['mapKey']),
  },

  methods: {
    ...mapActions(['getUserSettings']),

    initializeMap() {
      this.mapContainer = this.$refs.googleMap

      this.map = new this.google.maps.Map(
        this.mapContainer, this.mapSettings
      );

      this.initCenterMarker(this.mapContainer);
      this.initEvents();
      this.getCurrentLocation();

      this.mapMarkers = [];
      this.initMarkers();

      this.initPath();

      this.initBounds();
    },

    initCenterMarker() {
      if (!this.centerMarkerEnabled) {
        return;
      }

      this.centerMapMarker = document.createElement('div');
      this.centerMapMarker.className = `marker ${this.centerMarker}`;
      this.mapContainer.appendChild(this.centerMapMarker);
    },

    addTenMinutesToDuration(durationString) {
      const regex = /(\d+) hour[s]* (\d+) min[s]*|(\d+) hour[s]*|(\d+) min[s]*/g;
      const matches = durationString.match(regex);

      let hours = 0;
      let minutes = 0;

      if (matches) {
        matches.forEach(match => {
          const parts = match.split(' ');
          if (parts.length === 2) {
            if (parts[1] === 'hours' || parts[1] === 'hour') {
              hours += parseInt(parts[0]);
            } else if (parts[1] === 'mins' || parts[1] === 'min') {
              minutes += parseInt(parts[0]);
            }
          } else if (parts.length === 4) {
            hours += parseInt(parts[0]);
            minutes += parseInt(parts[2]);
          }
        });
      }

      minutes += 10;

      hours += Math.floor(minutes / 60);
      minutes %= 60;

      let result = '';
      if (hours > 0) {
        result += hours + ' hour' + (hours > 1 ? 's' : '') + ' ';
      }
      if (minutes > 0) {
        result += minutes + ' min' + (minutes > 1 ? 's' : '');
      }

      return result;
    },
    initMarkers() {
      this.markers.forEach(({ position, url, type, index , data}) => {

        const mapMarker = new this.google.maps.Marker({
          position,
          icon: {
            url: index === 0
              ? require('@/assets/images/icons/pickup.png')
              : (index !== undefined)
                ? require('@/assets/images/icons/dropoffs.png')
                : url,
            scaledSize: new this.google.maps.Size(48, 48),
          },
          map: this.map,
          draggable: true,
          customData: type,
          infoWindow: null,
        });

        if (index > 0) {
          mapMarker.setLabel({
            text: `${index}`,
            color: '#00204a',
            className: 'custom-marker-label',
          });
        }

        mapMarker.addListener("dragend", (data) => {
          this.$emit('dragMarker', { data, marker: mapMarker, index: index });
        });

        mapMarker.addListener('click', () => {
          if (data && !!data.distance && !!data.duration) {
            const updatedDuration = this.addTenMinutesToDuration(data.duration);
            const infoWindowContent = `
              <div>
                <strong>Distance:</strong> ${data?.distance} <br/>
                <strong>Duration:</strong> ${updatedDuration}
              </div>
            `;

            if (mapMarker.infoWindow) {
              mapMarker.infoWindow.setContent(infoWindowContent);
              mapMarker.infoWindow.open(this.map, mapMarker);
            } else {
              mapMarker.infoWindow = new this.google.maps.InfoWindow({
                content: infoWindowContent,
              });
              mapMarker.infoWindow.open(this.map, mapMarker);
            }
          }
        });

        if (data && !!data.distance && !!data.duration) {
          const updatedDuration = this.addTenMinutesToDuration(data.duration);
          const infoWindowContent = `
            <div>
              <strong>Distance:</strong> ${data?.distance} <br/>
              <strong>Duration:</strong> ${updatedDuration}
            </div>
          `;

          if (mapMarker.infoWindow) {
            mapMarker.infoWindow.setContent(infoWindowContent);
            mapMarker.infoWindow.open(this.map, mapMarker);
          } else {
            mapMarker.infoWindow = new this.google.maps.InfoWindow({
              content: infoWindowContent,
            });
          }
          
          setTimeout(() => {
            mapMarker.infoWindow.open(this.map, mapMarker);
            this.google.maps.event.trigger(this.map, 'resize');
          }, 100);
        }

        this.mapMarkers.push(mapMarker);
      });
    },

    removeMarkers() {
      if(this.mapMarkers){
        this.mapMarkers.forEach(marker => marker.setMap(null));
      }

      this.mapMarkers = [];
    },

    initPath() {
      if (this.path.length > 0){
        const origin = new this.google.maps.LatLng(this.path[0].lat, this.path[0].lng);
        const last_path = this.path[this.path.length - 1];
        const destination = new this.google.maps.LatLng(last_path.lat, last_path.lng);
        const request = {
          origin,
          destination,
          travelMode: 'DRIVING'
        }
        const map = this.map;
        const google = this.google;
        const directionsRenderer = this.directionsRenderer;
        const directionsService = new this.google.maps.DirectionsService();
        if (this.path.length > 2){
          request.waypoints = this.path.slice(1, -1).map(function (item){
            return {
              location: new google.maps.LatLng(item.lat, item.lng),
              stopover: false,
            };
          });
        }
        directionsService.route(request, function (result, status){
          if (status == "OK"){
            directionsRenderer.setMap(map);
            directionsRenderer.setDirections(result);
          }
        });
      }

    },

    removePath() {
      this.directionsRenderer.setMap(null);
    },

    initBounds() {
      if (! this.bounds.length) {
        return;
      }

      this.mapBounds = new this.google.maps.LatLngBounds();

      this.bounds.forEach(boundary => this.mapBounds.extend(boundary));

      this.map.fitBounds(this.mapBounds);
    },

    removeBounds() {
      this.mapBounds = null;
      this.map.fitBounds(null);
    },

    initEvents() {
      this.map.addListener('idle', () => this.centerChanged());
      this.map.addListener('click', (data) => {
        this.$emit('dropPin', data);
      });
    },

    centerChanged() {
      this.$emit('centerChanged', {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      });
    },

    getCurrentLocation() {
      if (this.geolocationEnabled) {
        navigator.geolocation.getCurrentPosition((position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }

          this.map.setCenter(location);

          this.getUserSettings(location);
          this.centerChanged();
        }, (error) => console.log(error));
      }
    }
  },

  watch: {
    center() {
      if (! this.map || ! this.center) {
        return;
      }

      this.map.setCenter(this.center);
    },

    centerMarker() {
      if (! this.map) {
        return;
      }

      this.centerMapMarker?.remove();
      this.initCenterMarker();
    },

    centerMarkerEnabled() {
      if (! this.map) {
        return;
      }

      this.centerMapMarker?.remove();
      this.initCenterMarker();
    },

    markers() {
      if (! this.map) {
        return;
      }

      this.removeMarkers();
      this.initMarkers();
    },

    path() {
      if (! this.map) {
        return;
      }

      this.removePath();
      this.initPath();
    },


    bounds(newValue) {
      if (! this.map && newValue.length < 2) {
        return;
      }

      this.initBounds();
    },
    async mapKey(val){
      const googleMapApi = await new Loader({
        apiKey: val,
        version: "weekly",
        libraries: ['places'],
      }).load();

      this.google = googleMapApi;
      this.geocoder = new this.google.maps.Geocoder();
      this.geocoderOK = this.google.maps.GeocoderStatus.OK;
      this.directionsRenderer = new this.google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#00B7C2',
          strokeOpacity: 0,
          strokeWeight: 2,
          icons: [
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeOpacity: 1,
                scale: 4,
              },
              offset: "0",
              repeat: "20px",
            },
          ]
        }
      });
      this.initializeMap();
      this.centerChanged();
    }
  },
}
</script>

<style>
.google-map {
  min-height: 500px;
  height: 100%;
  width: 100%;
}

.custom-marker-label {
  font-weight: bold;
  font-size: 14px;
  position: relative;
  top: -3px;
  left: -1px;
}

.custom-info-window {
  background-color: #ffffff;
  padding: 1px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-info-window p {
  margin: 0;
  font-size: 14px;
  color: #333333;
}

p.from_point {
    margin: 2px 0;
    font-size: 14px;
    text-decoration: underline;
}
</style>
