import {
  Login,
  Signup,
  ForgotPassword,
  ResetPassword,
  Profile,
} from '@/views/pages/auth';

export default [
  {
    path: '/',
    name: 'LoginIndex',
    component: Login,
    meta: {
      guestOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guestOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      guestOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      guestOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      guestOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      authOnly: true,
      transition: 'fade'
    },
  },
];
