<template>
  <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
    <div class="card box" @click="goOrderDetail(value.id)">
      <div class="card-body">
        <div class="card-title d-flex justify-content-between align-items-center">
          <p class="id-title-order mb-0">#{{ value.id }}Pass</p>
          <p class="price-title-order mb-0">QAR{{ value.user_price }}</p>
        </div>
        <hr class="header-divider">
        <div class="row mt-3">
          <div class="col-1 d-table my-auto">
            <span class="new-circle"></span>
          </div>
          <div class="col-9 col-md-10 d-table my-auto">
            <p class="order-pickup mb-0">Pickup</p>
            <p class="order-pickup-address">{{ value.sender_address }}</p>
          </div>
          <template v-for="dropoff in value.receiver_info" :key="dropoff.address">
            <div>
              <hr class="order-split">
            </div>
            <div class="col-1 mt-3" style="z-index: 1;">
              <span class="new-square"></span>
            </div>
            <div class="col-10 col-md-10 d-table my-auto">
              <p class="order-dropof mb-0">Dropoff</p>
              <p class="order-dropof-address">{{ dropoff.address }}</p>
            </div>
          </template>
          <hr class="header-divider">
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-3">
            <p class="order-sectio3-box">Return: <span
                class="order-sectio3-box-span ps-1">{{ value.return_trip ? "Yes" : "No" }}</span></p>
          </div>
          <div class="col-9">
            <p class="order-sectio3-box">Order Status:<span class="order-sectio3-box-span ps-1"> {{
                value.OrderStatus
              }}</span></p>
          </div>
          <div class="col-12">
            <p class="order-sectio3-box">Note For driver: <br/>
            <span class="text-info">{{ value?.receiver_info[0].description }}</span>
            </p>
          </div>
          <div class="col-12 ">
              <p class="order-sectio3-box">Schedule : <span class="text-info"> {{ value?.schedule ? value?.schedule : 'immediate' }}</span> </p>
          </div>
          <div>
            <!-- <button  @click.stop="goTrackOrders(value.id)" class="btn btn-primary btn-track-order">
              Track order
            </button> -->
            <button v-if="value.OrderStatus.toLowerCase() !== 'cancelled'" @click="goTrackOrders(value.id, $event)" class="btn btn-primary btn-track-order">
              Track order
            </button>
          </div>
        </div>
        <!-- <hr class="header-divider">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-center">
            <img :src="driver_avatar" class="driver-img" :alt="driver_name">
            <div class="d-flex flex-column justify-content-start align-items-stretch">
              <h3 class="driver-name">{{driver_name}}</h3>
              <h6 class="driver-type">Driver</h6>
            </div>
          </div>
          <button class="invoice-button" @click.stop="downloadInvoiceBtn(value.id, $event)" v-if="value.order_status == 'complete'">
            <img src="@/assets/images/icons/pdf-file.png" alt=""
                 style="width:25px" class="pe-2"> Invoice
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <a href="" ref="downloadTag" style="display: none;"></a>
</template>

<script>
import { orders } from '@/api';
import { peyk } from '@/configs';
export default {
  name: "PastOrder",
  props: ["value"],
  methods: {
    goOrderDetail(orderID) {
      this.$router.push({name: 'OrderDetails', params: { id: orderID}});
    },
    async downloadInvoiceBtn(id, e) {
      const buttonTag = e.composedPath().find((el)=>el.classList.contains("invoice-button"));
      const buttonContent = buttonTag.innerHTML;
      try {
        buttonTag.setAttribute('disabled',"");
        buttonTag.innerHTML = `<span style="width: 25px; height: 25px; margin: 0; padding: 0;" class="spinner-border"></span>`;
        const response = await orders.downloadInvoice(id);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const downloadTag = this.$refs.downloadTag;
        downloadTag.href = url;
        downloadTag.download = `Invoice Order #${id}`;
        downloadTag.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }finally {
        buttonTag.removeAttribute('disabled');
        buttonTag.innerHTML = buttonContent;
      }
    },
    goTrackOrders(orderID) {
     
      const url = `${peyk.host}track-order/${orderID}`; 
      window.open(url, '_blank');
    },
  },
  computed: {
    driver_name() {
      if (this.value?.driver_information?.first_name){
        return this.value?.driver_information?.first_name + ' ' + this.value?.driver_information?.last_name;
      }
      return "Unassigned Driver";
    },
    driver_avatar() {
      if (this.value?.driver_information?.avatar){
        return this.value?.driver_information?.avatar;
      }
      return require("@/assets/images/icons/driver-avatar.png");
    }
  },
}
</script>

<style scoped>
.box{
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 2px;
  margin: 1rem;
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border:none !important;
}

.box:hover{
  background: #fafafa;
  box-shadow: 0 10px 20px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
}
.invoice-button{
  outline: none;
  border: 1px solid #F2DDC8;
  padding: 18px 22px;
  background: #FBF4EE;
  border-radius: 10px;
}
.new-circle{
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: #00B7C2;
}
.new-square{
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 10px;
  background: #00204A;
}
.driver-img{
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin-right: 20px;
}
.driver-name{
  font-family: 'Nunito', sans-serif;
  font-weight: normal;
  font-size: 24px;
  color: #00204A;
  margin-bottom: 7px;
}
.driver-type{
  margin: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #BCBCBC;
}
</style>
