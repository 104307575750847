<template>
  <Collapse v-model="bodyVisible" :class="{disabled: !vehicles[0] && !promoCounter}">
    <template v-slot:header>
      <slot>
        <div class="wallet-header">
          <img src="@/assets/images/icons/color-wallet.png" class="wallet-icon" alt="wallet">
          <span class="change-color">Payment</span>
        </div>
      </slot>
    </template>
    <template v-slot:default>
      <div class="price-container">
        <div class="price">
          <span>Price</span>
          <span class="price-number">QAR {{vehicles[0]?.discount_price}}</span>
        </div>
        <div class="promo">
          <div class="promo-add" v-if="showAdd" @click="addPromo">
            <span class="promo-icon"><i class="bi bi-plus"></i></span>
            <span>Add Promo</span>
          </div>
          <div class="promo-remove" @click="removePromo" v-else>
            <span>Remove Promo</span>
          </div>
          <template v-if="!showAdd">
            <div class="promo-input">
              <input type="text" v-model="form.discount_code" class="form-contol" placeholder="Enter your promo code">
              <button type="button" @click="submit">Confirm</button>
            </div>
            <InputError
                :form="form"
                :fieldVuelidate="v$"
                :errors="form.validator.errors"
                field="discount_code"
                name="Discount Code"
                v-if="form.validator.errors.data?.discount_code"
            />
            <div class="promo-list">
              <div class="promo-item" v-for="discount in discount_list" :key="discount.code" @click.prevent="form.discount_code = discount.code">
                <span>{{discount.title}}</span>
                <span>{{discount.amount}}{{discount.type == "percent" ? "%" : "QAR"}}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="payment">
          <span class="payment-text">
            Choose your payment method
          </span>
          <div class="payment-method" :class="{active: dataTypePayment == 'wallet'}" @click="changeMethod('wallet')">
            <span class="payment-method-text">Wallet</span>
            <span class="payment-method-balance">
              <span class="change-color">Balance</span>
              <span>QAR {{balance}}</span>
            </span>
          </div>
          <div class="payment-method" :class="{active: dataTypePayment == 'cash'}" @click="changeMethod('cash')">
            <span class="payment-method-text">Cash on Delivery</span>
          </div>
        </div>
      </div>
    </template>
  </Collapse>
</template>

<script>
import { inject } from 'vue';
import Collapse from '../Collapse.vue';
import {mapActions, mapGetters, mapState} from "vuex";
import {PriceForm} from "@/forms/orders";
import {InputError} from "@/components/forms";
import {toaster} from "@/common/helpers";

export default {
  name: "Payment",
  components: {Collapse, InputError},
  emits: ['paymentTypeChange', 'update:modelValue', 'changeVehicle', 'paymentError'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    checkPrice:{
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      bodyVisible: false,
      dataTypePayment: "cash",
      showAdd: true,
      promoCounter: 0,
    }
  },
  setup() {
    const form = PriceForm();
    const formPrice = inject('sharedForm');
    const { v$ } = form;

    return {
      form,
      formPrice,
      v$,
    }
  },
  methods: {
    ...mapActions('orders', ['clearVehicles', 'getDiscountList']),
    addPromo(){
      this.showAdd = false;
    },
    removePromo(){
      this.showAdd = true;
      if (!this.vehicles[0] || this.form.discount_code){
        this.form.discount_code = null;
        this.submit();
      }
    },
    changeMethod(type){
      this.dataTypePayment = type;
    },
    async submit() {
      if (this.form.validator.errors.data?.discount_code){
        delete this.form.validator.errors.data.discount_code;
      }
      if (this.form.discount_code){
        this.promoCounter++;
      }else {
        this.promoCounter = 0;
      }
      this.selectVehicle(null);
      await this.clearVehicles();
      Object.keys(this.formPrice).forEach(key => {
        this.form[key] = this.formPrice[key];
      });
      if(this.form.pickup.lat !== null && this.form.pickup.long !== null && this.form.dropoff_1.lat !== null && this.form.dropoff_1.long !== null){
        await this.form.submit();
      }
    },
    assignPoint(source, target) {
      source.lat = target.lat;
      source.long = target.long;
    },
    selectVehicle(vehicleId , price) {
      this.form.vehicle_id = vehicleId;
      this.form.discount_price = price
      const { vehicle_id, discount_code ,vehicle , quantity , discount_price  } = this.form.data;

      const data = {
        ...this.modelValue,
        vehicle_id,
        discount_code,
        vehicle,
        quantity,
        discount_price,
      };

      this.$emit('update:modelValue', data);
      this.$emit('changeVehicle', data);
    },
    toggleShowPromoCode() {
      this.showPromoCode = ! this.showPromoCode;

      if (! this.showPromoCode) {
        this.form.discount_code = null;
        this.formPrice.discount_code = null;
        Object.keys(this.formPrice).forEach(key => {
          this.form[key] = this.formPrice[key];
        });
        this.form.submit();
      }
    },
    assignForm() {
      this.assignPoint(this.form.pickup, this.modelValue.addresses[0]);
      const dropOffLen = Object.keys(this.form).filter(key => key.startsWith('dropoff_')).length;
      
      for (let i = 1; i <= dropOffLen; i++) {
        const dropoffKey = `dropoff_${i}`;
        this.assignPoint(this.form[dropoffKey], this.modelValue.addresses[i]);
      }

      const { vehicle_id, discount_code ,discount_price ,price } = this.modelValue;
      this.form.data = Object.assign(this.form.data, {
        vehicle_id,
        discount_code,
        discount_price,
        price,
      });
    },
    // assignForm() {
    //   this.assignPoint(this.form.pickup, this.modelValue.addresses[0])
    //   this.assignPoint(this.form.dropoff_1, this.modelValue.addresses[1])
    //   this.assignPoint(this.form.dropoff_2, this.modelValue.addresses[2])
    //   this.assignPoint(this.form.dropoff_3, this.modelValue.addresses[3])

    //   const { vehicle_id, discount_code ,discount_price ,price } = this.modelValue;
    //   this.form.data = Object.assign(this.form.data, {
    //     vehicle_id,
    //     discount_code,
    //     discount_price,
    //     price,
    //   });
    // },
  },
  computed: {
    ...mapState("cards", ['balance']),
    ...mapState('orders', ['vehicles', 'discount_list']),
    ...mapGetters('auth', ['isBusinessUser'])
  },
  mounted() {
    this.getDiscountList();
  },
  watch: {
    dataTypePayment(val) {
      this.$emit('paymentTypeChange', val);
    },
    async checkPrice(val){
      if (val){
        this.$emit('paymentTypeChange', this.dataTypePayment);
        this.assignForm();
        await this.submit();
      }
    },
    vehicles(val){
      if (Object.keys(val).length){
        this.bodyVisible = true;
        if(Array.isArray(this.vehicles) && this.isBusinessUser) {
          let emptyPriceVehicles = this.vehicles.filter(item => item.price == 0)

          if (emptyPriceVehicles?.length) {
            this.$emit('paymentError', true)
            toaster().error('Selected Vehicle not available in this drop off area');
          } else {
            this.$emit('paymentError', false)
          }
        }
      }
    }
  },
}
</script>

<style scoped>
.wallet-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.wallet-header > .wallet-icon{
  width: 20px;
}
.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.change-color{
  color: #00204A;
}
.header-icon{
  margin-right: 13px;
}
.price-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1rem 1.25rem;
  gap: 29px;
  font-family: 'Nunito', sans-serif;
}
.price-container > .price{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 17px;
  background: #F2F2F2;
  border: 1px solid #CACACA;
  border-radius: 5px;
  color: #00B7C2;
  font-size: 18px;
}
.price-container > .promo{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
}
.price-container > .promo > .promo-add{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #00204A;
  font-size: 18px;
  cursor: pointer;
}
.price-container > .promo > .promo-remove{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #00204A;
  font-size: 18px;
  cursor: pointer;
}
.price-container > .promo > .promo-input{
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.price-container > .promo > .promo-input > input{
  flex-grow: 3;
  padding: 13px 19px;
  border: 1px solid #cacaca;
  border-right: none;
  border-radius: 5px 0 0 5px;
}
.price-container > .promo > .promo-input > button{
  flex-grow: 1;
  padding: 12px 16px;
  border: 1px solid #cacaca;
  border-left: none;
  background: #00B7C2;
  border-radius: 0 5px 5px 0;
  color: #fff;
}
.price-container > .promo > .promo-list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  color: #00204A;
}
.price-container > .promo > .promo-list > .promo-item{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-container > .promo > .promo-code{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00204A;
  font-size: 18px;
}
.price-container > .payment{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 29px;
  border-top: 1px solid #cacaca;
  font-size: 18px;
  gap: 16px;
}
.price-container > .payment > .payment-text{
  color: #373737;
}
.price-container > .payment > .payment-method{
  padding: 13px 17px;
  border-radius: 4px 4px 5px 5px;
  color: #373737;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.price-container > .payment > .payment-method.active{
  color: #00204A;
  background: #F2DDC8;
}
.price-container > .payment > .payment-method > .payment-method-balance{
  color: #00B7C2;
}
.price-container > .payment > .payment-method > .payment-method-balance > .change-color{
  color: #00204A;
  font-size: 12px;
  margin-right: 7px;
}
</style>