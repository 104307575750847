<template>
  <div id="peyk_bodynav">
    <div class="container-fluid pb-2 ps-4 ">
      <PageTitle :noLine="true" title="Favorites" />
    </div>

    <div class="container-fluid mb-2 ps-4  pt-2">
      <div class="row">
        <div class="col-12 mb-2">
          <router-link to="/add-favorite" class="v_link add-favorite-link">
            <img src="@/assets/images/icons/adddrop.png">
            <span>Add Favourites</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="container-fluid ps-4">
      <div class="row">
        <div class="col-12 table-container">
          <div class="table-head">
            <span></span>
            <span class="name">Name</span>
            <span class="address">Address</span>
            <span>Flat</span>
            <span>House number/Name</span>
            <span>Sender Name</span>
            <span>Phone Number</span>
            <span></span>
          </div>
          <div class="table-data" v-for="favorite in favorites" :key="favorite.id">
            <span>
              <img src="@/assets/images/icons/Home.png" alt="home" v-if="favorite.type === 'Home'">
              <img src="@/assets/images/icons/work-2.png" alt="work" v-else-if="favorite.type === 'Work'">
              <img src="@/assets/images/icons/location.png" alt="location" v-else>
            </span>
            <span class="name">{{ favorite.name }}</span>
            <span class="address">{{ favorite.address }}</span>
            <span>{{favorite.flat}}</span>
            <span>{{favorite.house}}</span>
            <span>{{favorite.sender_name}}</span>
            <span>{{favorite.phone_number}}</span>
            <span>
              <router-link
                  class="trash-button-peyk"
                  :to="{name: 'UpdateFavorite', params: {id: favorite.id}}"
                  style="margin-right: 1em;"
              >
                      <img src="@/assets/images/icons/pencil-edit.svg" alt="edit">
                    </router-link>
                    <button
                        type="button"
                        class="trash-button-peyk p-0"
                        @click="removeItem(favorite.id)"
                    >
                      <img src="@/assets/images/icons/trash-fill.svg" alt="delete">
                    </button>
            </span>
          </div>
        </div>
        <nav aria-label="Page navigation" v-if="paginateInfo.last_page !== 1">
          <ul class="pagination justify-content-center align-items-center">
            <li class="page-item" :class="{'disabled': paginateInfo.current_page === 1}"><button class="page-link" @click="changePage(paginateInfo.current_page - 1)" :disabled="paginateInfo.current_page === 1">Previous</button></li>
            <li class="page-item" :class="{'active': paginateInfo.current_page === page + 1}" v-for="page in pages" :key="page"><button class="page-link" @click="changePage(page + 1)">{{page + 1}}</button></li>
            <li class="page-item" :class="{'disabled': paginateInfo.current_page === paginateInfo.last_page}"><button class="page-link" @click="changePage(paginateInfo.current_page + 1)" :disabled="paginateInfo.current_page === paginateInfo.last_page">Next</button></li>
          </ul>
        </nav>
      </div>
    </div>
</div>

</template>

<script>
import { PageTitle } from '@/components';
import { mapState, mapActions } from 'vuex';

export default {
  name: "Favorites",

  components: {
    PageTitle,
  },

  computed: {
    ...mapState({
      favorites: ({ favorites }) => favorites.list,
      paginateInfo: ({ favorites }) => favorites.paginateInfo,
    }),
    pages(){
      return [...Array(this.paginateInfo.last_page).keys()];
    }
  },

  async mounted() {
    await this.getFavorites();
  },

  methods: {
    ...mapActions('favorites', ['getFavorites', 'removeFavorite']),
    async changePage(page){
      await this.getFavorites(page)
    },
    async removeItem(id){
      await this.removeFavorite(id);
      // check if page is empty request for previous page otherwise request for current page
      await this.getFavorites(this.favorites.length === 0 ? (this.paginateInfo.current_page === 1 ? this.paginateInfo.current_page : this.paginateInfo.current_page - 1) : this.paginateInfo.current_page);
    }
  },
}

</script>
<style scoped>
#peyk_bodynav {
  padding-left: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.peyk-table-style  td , th {
  text-align: center;
  vertical-align: middle;
}
.add-favorite-link{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.add-favorite-link > img{
  width: 35px;
}
.add-favorite-link > span{
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #00204A;
}
.table-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;
}
.table-head, .table-data{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.table-head, .table-data{
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-weight: normal;
  padding: 14px 16px;
  color: #BBBBBB;
}
.table-data{
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  color: #00204A;
}
.table-data > span.name{
  font-weight: bold;
}
.table-head > span, .table-data > span{
  flex: 2;
}
.table-head > span:first-child, .table-data > span:first-child, .table-head > span:last-child, .table-data > span:last-child{
  flex: 1;
}
.table-head > span.name, .table-data > span.name{
  flex: 2;
}
.table-head > span.address, .table-data > span.address{
  flex: 6;
}
nav{
  margin-top: 40px;
}
</style>
<style scoped src="@/assets/css/favorites.css"></style>
