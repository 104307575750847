export default {
  isLoading: false,

  isActiveMenu : true, //for managing show and hide sidebar menu

  cityId: 2,

  location: {
    lat: 51.507387,
    lng: -0.127590,
  },

  mapKey: "",

}
