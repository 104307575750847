import { favorites } from '@/api';
import { showMessage, isSuccessful } from '@/common/helpers';

const state = () => ({
  list: [],
  paginateInfo: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0
  },
  icons: {
    Home: 'bi-house',
    Work: 'bi-building',
    Relatives: 'bi-people',
    Other: 'bi-patch-question',
  }
});

const mutations = {
  setFavorites(state, payload) {
    state.list = payload;
  },

  setPaginate(state, payload) {
    state.paginateInfo = payload;
  },

  addToFavorite(state, payload){
    const {list} = state;
    state.list = [...list, ...payload]
  },

  addFavorite(state, payload) {
    const { list } = state;

    list.unshift(payload);

    state.list = [...list];
  },

  updateFavorite(state, payload) {
    const { list } = state;

    let index = list.findIndex(item => item.id === payload.id);

    list[index] = payload;

    state.list = [...list];
  },

  removeFavorite(state, payload) {
    state.list = [...state.list.filter(favorite => favorite.id !== payload)];
  },
}

const actions = {
  async getFavorites({ commit }, payload) {
    if (!payload){
      payload = 1;
    }
    commit('setIsLoading', true, { root: true });
    const { data } = await favorites.getList(payload);
    commit('setIsLoading', false, { root: true });
    commit('setFavorites', data.data.result);
    commit('setPaginate', {
        current_page: data.data.current_page,
        last_page: data.data.last_page,
        per_page: data.data.per_page,
        total: data.data.total
    })
  },
  async addGetFavorites({ commit }, payload) {
    if (!payload){
      payload = 1;
    }
    commit('setIsLoading', true, { root: true });
    const { data } = await favorites.getList(payload);
    commit('setIsLoading', false, { root: true });
    commit('addToFavorite', data.data.result);
    commit('setPaginate', {
        current_page: data.data.current_page,
        last_page: data.data.last_page,
        per_page: data.data.per_page,
        total: data.data.total
    })
  },

  async addFavorite({ commit }, payload) {
    const { data } = await favorites.add(payload);

    commit('addFavorite', data.data);

    showMessage(data);

    return isSuccessful(data);
  },

  async updateFavorite({ commit }, payload) {
    const { data } = await favorites.update(payload);

    commit('updateFavorite', data.data);

    showMessage(data);

    return isSuccessful(data);
  },

  async removeFavorite({ commit }, payload) {
    const { data } = await favorites.remove(payload);

    commit('removeFavorite', payload);

    showMessage(data);
  },
}

const getters = {
  //
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
