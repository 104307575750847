import {
  NewOrder,
  BulkOrder,
  CurrentOrder,
  OrderDetails,
  OrderTracking
} from '@/views/pages/orders';

export default [
  {
    path: "/new-order",
    name: "NewOrder",
    component: NewOrder,
    meta: {
      authOnly: true,
      transition: 'fade'
    }
  },

  {
    path: "/bulk-order",
    name: "BulkOrder",
    component: BulkOrder,
    meta: {
      authOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/order-list',
    name: 'CurrentOrder',
    component: CurrentOrder,
    meta: {
      authOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/order-details/:id',
    name: 'OrderDetails',
    component: OrderDetails,
    props: true,
    meta: {
      authOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/order-tracking/:id',
    name: 'OrderTracking',
    component: OrderTracking,
    props: true,
    meta: {
      authOnly: true,
      transition: 'fade',
    }
  }

];
