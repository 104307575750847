import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import registerDirectives from './directives';

import Toaster from '@meforma/vue-toaster';

const app = createApp(App);

app.use(store)
  .use(router)
  .use(Toaster, {
    position: 'top-right',
  }).mount('#app');

registerDirectives(app);

window.$app = app;
