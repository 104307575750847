<template>
  <div class="h-100">
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
          :google="google"
          :map="map"
      />
    </template>
  </div>
</template>

<script>
import {Loader} from "@googlemaps/js-api-loader";
import {googleMap} from '@/configs';
import {mapActions, mapState} from 'vuex';

export default {
  props: {
    settings: {
      type: Object,
      default: googleMap.settings,
    },

    center: {
      type: Object,
      default: googleMap.settings.center,
    },

    centerMarkerEnabled: {
      type: Boolean,
      default: false,
    },

    geolocationEnabled: {
      type: Boolean,
      default: false,
    },

    centerMarker: {
      type: String,
      default: 'default-marker',
    },

    markers: {
      type: Array,
      default: () => [],
    },

    bounds: {
      type: Array,
      default: () => [],
    },

    path: {
      type: Array,
      default: () => [],
    },

    routePath: {
      type: Array,
      default: () => [],
    }

  },

  data() {
    return {
      google: null,
      map: null,
      directionsRenderer: null,
    }
  },

  async mounted() {
    if (this.mapKey != ""){
      const googleMapApi = await new Loader({
        apiKey: this.mapKey,
        version: "weekly",
        libraries: ['places'],
      }).load();

      this.google = googleMapApi;
      this.initializeMap();
      this.centerChanged();
    }
  },

  computed: {
    mapSettings() {
      let settings = {...this.settings};

      return settings;
    },
    ...mapState(['mapKey']),

  },

  methods: {
    ...mapActions(['getUserSettings']),

    updateBound() {
      this.initMarkers();
    },

    initializeMap() {
      this.mapContainer = this.$refs.googleMap

      this.map = new this.google.maps.Map(
          this.mapContainer, this.mapSettings
      );

      this.initCenterMarker(this.mapContainer);
      this.initEvents();
      this.getCurrentLocation();

      this.mapMarkers = [];
      this.initMarkers();

      this.mapRoutePath = [];
      this.initRoutePath();

      this.initBounds();
    },

    initCenterMarker() {
      if (!this.centerMarkerEnabled) {
        return;
      }

      this.centerMapMarker = document.createElement('div');
      this.centerMapMarker.className = `marker ${this.centerMarker}`;
      this.mapContainer.appendChild(this.centerMapMarker);
    },

    initMarkers() {
      this.markers.forEach(({position, url, index}) => {
        const mapMarker = new this.google.maps.Marker({
          position,
          icon: {
            url: index === 0
              ? require('@/assets/images/icons/pickup.png')
              : (index !== undefined)
                ? require('@/assets/images/icons/dropoffs.png')
                : url,
            scaledSize: new this.google.maps.Size(48, 48),
          },
          map: this.map,
        });

        if (index && index > 0) {
          mapMarker.setLabel({
            text: `${index}`,
            color: '#00204a',
            className: 'custom-marker-label',
          });
        }

        this.mapMarkers.push(mapMarker);
      });
    },

    removeMarkers() {
      if (this.mapMarkers) {
        this.mapMarkers.forEach(marker => marker.setMap(null));
      }

      this.mapMarkers = [];
    },

    initPath() {
      if (this.path.length > 0){
        const origin = new this.google.maps.LatLng(this.path[0].lat, this.path[0].lng);
        const last_path = this.path[this.path.length - 1];
        const destination = new this.google.maps.LatLng(last_path.lat, last_path.lng);
        const request = {
          origin,
          destination,
          travelMode: 'DRIVING'
        }
        const map = this.map;
        const google = this.google;
        const directionsRenderer = this.directionsRenderer;
        const directionsService = new this.google.maps.DirectionsService();
        if (this.path.length > 2){
          request.waypoints = this.path.slice(1, -1).map(function (item){
            return {
              location: new google.maps.LatLng(item.lat, item.lng),
              stopover: false,
            };
          });
        }
        directionsService.route(request, function (result, status){
          if (status == "OK"){
            directionsRenderer.setMap(map);
            directionsRenderer.setDirections(result);
          }
        });
      }
    },

    initRoutePath(){
      this.mapRoutePath = new this.google.maps.Polyline({
        path: this.routePath,
        geodesic: true,
        strokeColor: '#00204A',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        map: this.map,
      });

    },

    removeRoutePath(){
      this.mapRoutePath.setMap(null);
    },

    initBounds() {
      if (!this.bounds.length) {
        return;
      }

      this.mapBounds = new this.google.maps.LatLngBounds();

      this.bounds.forEach(boundary => this.mapBounds.extend(boundary));

      this.map.fitBounds(this.mapBounds);
    },

    removeBounds() {
      this.mapBounds = null;
    },

    initEvents() {
      this.map.addListener('idle', () => this.centerChanged());
    },

    centerChanged() {
      this.$emit('centerChanged', {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      });
    },

    getCurrentLocation() {
      if (this.geolocationEnabled) {
        navigator.geolocation.getCurrentPosition((position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }

          this.map.setCenter(location);

          this.getUserSettings(location);
          this.centerChanged();
        });
      }
    }
  },

  watch: {
    centerMarker() {
      if (!this.map) {
        return;
      }

      this.centerMapMarker?.remove();
      this.initCenterMarker();
    },

    centerMarkerEnabled() {
      if (!this.map) {
        return;
      }

      this.centerMapMarker?.remove();
      this.initCenterMarker();
    },

    markers() {
      if (!this.map) {
        return;
      }

      this.removeMarkers();
      this.initMarkers();
    },


    routePath(){
      if (!this.map) {
        return;
      }

      this.removeRoutePath();
      this.initRoutePath();
    },

    bounds() {
      if (!this.map) {
        return;
      }

      this.removeBounds();
      this.initBounds();
    },
    async mapKey(val){
      const googleMapApi = await new Loader({
        apiKey: val,
        version: "weekly",
        libraries: ['places'],
      }).load();

      this.google = googleMapApi;
      this.initializeMap();
      this.centerChanged();
    }
  },
}
</script>

<style>
.google-map {
  min-height: 500px;
  height: 100%;
  width: 100%;
}
.custom-marker-label {
    font-weight: bold;
    font-size: 14px;
    position: relative;
    top: -3px;
    left: -1px;
  }
</style>
