<template>

  <div class="container-fluid">
    <div class="row set-max-height">
      <!-- ****************** left side bar ********************* -->
      <div class="col-md-6 order-md-first">
        <div class="row ">
          <div class="col-md-8 offset-md-2 col-10 offset-1">

            <div class="header-form header-form-peyk">
              <img src="@/assets/images/icons/logo.png" class="mt-5" width="100">
              <h1 class="mt-5 pt-5">Sign Up</h1>
              <p class="my-3">Send anything to anywhere in minutes</p>
            </div>

            <div class="body-form mb-5 pb-5 mt-4">

              <div class="tab-selector">
                <button :class="{active: individualTab}"  @click="handleTab(true)">Individual</button>
                <button :class="{active: !individualTab}" @click="handleTab(false)">Business</button>
              </div>

              <div class="tab-content mt-3" id="myTabContent">
                <!-- ********************* Individuals tabs ********************* -->
                <div class="tab-pane fade show active" id="Individuals" role="tabpanel"
                     aria-labelledby="Individuals-tab" v-if="individualTab">
                  <form class="mt-4" @submit.prevent="registerUser">
                   <div class="row">
                     <div class="col-6 mb-2">
                       <Input
                           class=""
                           v-focus
                           :form="userForm"
                           :field="'first_name'"
                           :name="'First Name'"
                           :vuelidate="uv$"
                       />
                     </div>
                     <div class="col-6 mb-2">
                      <Input
                        class=""
                        :form="userForm"
                        :field="'last_name'"
                        :name="'last Name'"
                        :vuelidate="uv$"
                      />
                     </div>
                   </div>

                    <TelInput
                      class="col-12 mb-2"
                      :form="userForm"
                      :field="'mobile'"
                      :vuelidate="uv$"
                    />

                    <Input
                      class="col-12 mb-2"
                      :form="userForm"
                      :field="'password'"
                      :type="'password'"
                      :vuelidate="uv$"
                    />
                    <div class="terms">
                      <input type="checkbox" name="terms" id="terms" v-model="terms_input" style="display: none;"  />
                      <div class="fake-checkbox" @click="terms_input = !terms_input" :class="{checked: terms_input}">
                        <span><i class="bi bi-check"></i></span>
                      </div>
                      <label for="terms">I agree to the <a href="https://www.pass.qa/terms/" class="terms-link" target="_blank">Terms and Conditions</a>.</label>
                    </div>
                    <div class="terms-error text-danger hidden">You must accept our terms and conditions.</div>

                    <SubmitButton class="col-12 mt-4"
                      :form="userForm"
                      :vuelidate="uv$"
                    >Next</SubmitButton>
                  </form>
                </div>
                <div class="tab-pane fade show active" id="Individuals" role="tabpanel"
                     aria-labelledby="Individuals-tab" v-else>
                  <form class="mt-4" @submit.prevent="registerBusiness">

                    <Input
                        class="col-12 mb-2"
                        :form="businessForm"
                        :field="'business_name'"
                        :name="'Business Name'"
                        :vuelidate="bv$"
                        v-focus
                    />

                    <Input
                        class="col-12 mb-2"
                        :form="businessForm"
                        :field="'email'"
                        :name="'Email'"
                        :vuelidate="bv$"
                    />

                    <TelInput
                        class="col-12 mb-2"
                        :form="businessForm"
                        :field="'mobile'"
                        :vuelidate="bv$"
                    />

                    <Input
                        class="col-12 mb-2"
                        :form="businessForm"
                        :field="'password'"
                        :type="'password'"
                        :vuelidate="bv$"
                    />

                    <div class="terms">
                      <input type="checkbox" name="terms" id="terms" v-model="terms_input" style="display: none;"  />
                      <div class="fake-checkbox" @click="terms_input = !terms_input" :class="{checked: terms_input}">
                        <span><i class="bi bi-check"></i></span>
                      </div>
                      <label for="terms">I agree to the <a href="https://www.pass.qa/terms/" class="terms-link" target="_blank">Terms and Conditions</a>.</label>
                    </div>
                    <div class="terms-error text-danger hidden">You must accept our terms and conditions.</div>

                    <SubmitButton class="col-12 mt-4"
                                  :form="businessForm"
                                  :vuelidate="bv$"
                    >Next</SubmitButton>
                  </form>
                </div>
              </div>

              <div class="link-login-signup mt-3">
                <p class="me-2">Have an account?</p>
                <router-link to="/login" active-class="v_link">Login</router-link>
              </div>

            </div>

          </div>
        </div>
      </div>

      <!-- ****************** right side bar ********************* -->
      <div class="col-md-6 px-0 order-md-first order-first d-none d-sm-none d-md-block d-lg-block right-side">
        <figure class="signup-individual-page border-position-pics" v-if="individualTab"></figure>
        <figure class="signup-business-page border-position-pics" v-else></figure>
      </div>
    </div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { UserSignup, BusinessSignup } from '@/forms/signup';
import {
  Input,
  TelInput,
  SubmitButton,
} from '@/components/forms';

export default {
  name: 'Signup',

  components: {
    Input,
    TelInput,
    SubmitButton,
  },

  setup() {
    const userForm = UserSignup();
    const uv$ = userForm.v$;

    const businessForm = BusinessSignup();
    const bv$ = businessForm.v$;

    return {
      userForm,
      uv$,

      businessForm,
      bv$,
    }
  },

  data() {
    return {
      TabImage: 'individual',
      userFormErrors: [],
      businessFormErrors: [],
      terms_input: false,
      individualTab: true,
    }
  },
  methods: {
    ...mapMutations('auth', ['setRegistrationData']),

    handleTab(individual){
      if (individual){
        this.businessForm.reset();
        this.uv$.$reset();
      }else{
        this.userForm.reset();
        this.bv$.$reset();
      }
      this.individualTab = individual;
    },

    async registerUser() {
      if (! this.uv$.$invalid) {
        if (this.terms_input){
          const status = await this.userForm.submit();

          if ((typeof status === 'object' && status.status === 'success') || status === true) {
            this.uv$.$reset();
            this.$router.push({name: 'SignupVerification'});
          }
        }else{
          const terms_error = document.getElementsByClassName("terms-error")[0];
          terms_error.classList.remove("hidden");
        }
      }
    },

    async registerBusiness() {
      if (! this.bv$.$invalid) {
        if (this.terms_input){
          const status = await this.businessForm.submit();

          if ((typeof status === 'object' && status.status === 'success') || status === true) {
            this.bv$.$reset();
            this.$router.push({name: 'SignupVerification'});
          }
        }else{
          const terms_error = document.getElementsByClassName("terms-error")[0];
          terms_error.classList.remove("hidden");
        }
      }
    },
  },
  watch: {
    terms_input(newValue) {
      if (newValue){
        const terms_error = document.getElementsByClassName("terms-error")[0];
        if (!terms_error.classList.contains('hidden')){
          terms_error.classList.add("hidden");
        }
      }
    }
  },
}
</script>

<style scoped>
.tab-selector{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}
.tab-selector > button{
  flex: 1;
  padding: 1rem 0.75rem;
  border: 2px solid #efefef;
  border-radius: 0.375rem;
  background: #fff;
  color: #0a264a;
  transition: color 300ms linear, border 300ms linear;
}
.tab-selector > button.active{
  border: 2px solid #27abb7;
  color: #27abb7;
}
</style>
<style>
input:-webkit-autofill,
input:-webkit-autofill:focus ,
input:-webkit-autofill {
  appearance: none;
  background-color: #ffffff !important;
  background-image: none !important;
}
.terms{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.fake-checkbox{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  color: transparent;
  font-size: 24px;
  transition: all 200ms;
}
.fake-checkbox.checked{
  background: #50c5ce;
  color: #fff;
}
.hidden{
  display: none !important;
}
.terms-link{
  color: #50c5ce !important;
}
</style>
