import Api from "./api";

export default {
  addStripeCard(payload) {
    return Api().post("v1/user/add-card", payload);
  },

  getCards(payload) {
    return Api().get("v1/user/card-list", payload);
  },

  removeCard(payload) {
    return Api().post("v1/user/remove-card-bank", payload);
  },

  getBalance() {
    return Api().post("v1/user/get-balance");
  },

  getBalanceList(payload) {
    return Api().post("v1/user/get-balance-list", payload);
  },

  addCredit(payload) {
    return Api().post("v1/user/add-credit", payload);
  },
};
