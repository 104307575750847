<template>
    <input class="form-control google-url-input"
    type="text"
    placeholder="Paste Google link here"
    @input="$emit('UrlInput', $event.target.value)"
    >
</template>
<script>
export default {
  name: 'mapLink',
  props: {
        valueinput: {
            type: String,
        }
    },
}
</script>