import axios from "axios";
import store from '@/store';
import { peyk } from '@/configs';
import router from "@/router";
import { LocalStorageManager } from '@/services';
import { showMessage } from '@/common/helpers';

let BaseApi = axios.create({
  baseURL: peyk.host,
});

BaseApi.interceptors.response.use(function (response) {
  return response;
}, ({ response }) => {
  store.dispatch('endLoading');

  if (response.status !== 402) {
    showMessage(response.data);
  }

  switch (response.status) {
    case 401:
      LocalStorageManager.clearVuexStore();
      return router.push('/login');

    case 426:
      return router.push('/426');

    default:
      throw response.data;
  }
});

const api = () => {
  const token = LocalStorageManager.getToken();

  if (token) {
    BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  BaseApi.defaults.headers.common['os-type'] = peyk.os_type;
  BaseApi.defaults.headers.common['os-info'] = peyk.os_info;
  BaseApi.defaults.headers.common['api-version'] = peyk.api_version;
  BaseApi.defaults.headers.common['app-version'] = peyk.app_version;
  BaseApi.defaults.headers.common['app-type'] = peyk.app_type;

  return BaseApi;
};

export default api;
