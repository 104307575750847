import { createFocusTrap } from "focus-trap";


export default {
  mounted(el) {
    const trap = createFocusTrap(el);

    el.trap = trap;

    trap.activate();
  },

  unmounted(el) {
    el.trap.deactivate();
  }
}
