<template>
  <div id="peyk_bodynav">
    <div class="container-fluid pb-2 ps-4 ">
      <PageTitle :noLine="true" title="Payment"/>
    </div>

    <div class="container-fluid">
      <div class="row">
          <!-- ***************************** left side *************************************************** -->
        <div class="col-12 payment-container">
          <section class="balance-section">
            <div class="balance">
              <h3 class="balance-title">Total Balance:</h3>
              <span class="balance-amount">QAR{{balance}}</span>
            </div>
            <button class="btn btn-primary-peyk btn-lg balance-button" @click="modalShow=!modalShow">Add Balance</button>
          </section>
          <section class="history-section">
            <div class="history-title">
              <img src="@/assets/images/icons/receipt.svg" alt="receipt">
              <h3>Payment History</h3>
            </div>
            <div class="history-table">
              <div class="table-head">
                <span></span>
                <span>Type</span>
                <span>Order Number</span>
                <span>Date</span>
                <span>Time</span>
                <span>Amount</span>
              </div>
              <div class="table-data" v-for="(item,index) in balanceList" :key="index">
                <template v-if="item.type === 'in'">
                  <span>
                  <img src="@/assets/images/icons/payment-in.svg" alt="">
                </span>
                  <span class="payment-type payment-in">
                    <span>Deposit</span>
                  </span>
                </template>
                <template v-else>
                  <span>
                    <img src="@/assets/images/icons/payment-out.svg" alt="">
                  </span>
                  <span class="payment-type payment-out">
                    <span>Withdraw</span>
                  </span>
                </template>
                <span>#{{item.order_id}}</span>
                <span>{{item.created_at.split(' ')[0]}}</span>
                <span>{{item.created_at.split(' ')[1]}}</span>
                <span :class="{'payment-in': item.type === 'in', 'payment-out': item.type === 'out'}">QAR{{Math.abs(item.amount)}}</span>
              </div>
            </div>
          </section>
        </div>

        <Fade>
          <div class="custom-modal-background" @click.self="modalShow = false;" v-if="modalShow">
            <div class="custom-modal">
              <div class="modal-header">
                <span>Top up your wallet</span>
              </div>
              <div class="modal-body">
                <div class="button-section">
                  <button type="button" @click="amount=50" :value="50">50 QAR</button>
                  <button type="button" @click="amount=100" :value="100">100 QAR</button>
                  <button type="button" @click="amount=200" :value="200">200 QAR</button>
                </div>
                <div class="amount-section">
                  <button :disabled="amount<=0" @click="amount = parseInt(amount || '0') - 1">
                    <i class="bi bi-dash"></i>
                  </button>
                  <div>
                    <input class="amount-input" type="number" min="0" v-model="amount">
                    <span ref="inputCurrency" id="input-currency">QAR</span>
                  </div>
                  <button :disabled="amount>=1000" @click="amount = parseInt(amount || '0') + 1">
                    <i class="bi bi-plus-lg"></i>
                  </button>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary-peyk"
                        :disabled="isLoading || amount<= 0"
                        @click="submit" style="width: 100%">Apply</button>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  </div>
</template>

<script>

import { PageTitle} from '@/components';
import {mapActions, mapMutations, mapState} from "vuex";


export default {
  name: "Payment",
  components: {
    PageTitle,
  },
  data() {
    return {
      modalShow:false,
      amount :0,
      paymentHistory: false,
      addPaymentMethodModal: false,
      canvas: null,
      context: null,
    }
  },
  methods:{
    ...mapActions('cards' ,['getBalance' , 'getBalanceList' , 'addCreditAction']),
    ...mapMutations(['setIsLoading']),


    submit(){
      this.setIsLoading(true);
      this.addCreditAction({amount : this.amount}).then(()=>{
     this.goPaymentPage();
      this.modalShow=false;
      this.setIsLoading(false);
      });
 

    },
    
    goPaymentPage(){
      if(this.addCredit != ''){
        window.open(this.addCredit);
      }

    },
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('cards' , ['balance' , 'balanceList' , 'addCredit']),
  },
  watch: {
    amount(value) {

      this.context.font = "24px Bariol";
      const width = this.context.measureText(value).width;
      const finalWidth = Math.ceil(width) + "px";
      this.$refs.inputCurrency.style.left = `min(100%, calc(50% + ${finalWidth} + 50px))`;
    }
  },

  async mounted() {
    this.getBalance();
    this.getBalanceList({prepage:30})
    this.canvas = document.createElement("canvas");
    this.context = this.canvas.getContext("2d");
  }, // mounted

}
</script>

<style scoped>
#peyk_bodynav {
  padding-left: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: white;
}
.payment-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
  padding: 0 31px 31px 31px;
}
.balance-section{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 43px;
  font-family: 'Nunito', sans-serif;
  color: #00204A;
  padding: 22px 49px;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
}
.balance{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}
.balance-title{
  font-size: 38px;
  font-weight: bold;
}
.balance-amount{
  font-size: 38px;
  font-weight: bolder;
}
.currency{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 8px 10px;
  border: 1px solid #EAEAEA;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bolder;
}
.currency > img{
  width: 36px;
}
.balance-button{
  margin-left: auto;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.history-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  font-family: 'Nunito', sans-serif;
}
.history-title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
}
.history-title > h3{
  font-size: 24px;
  font-weight: bold;
  color: #00204A;
}
.history-table{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
}
.table-head{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: normal;
  color: #00204A;
}
.table-head > span{
  padding: 15px 0;
  flex: 2;
}
.table-head > span:first-child{
  flex: 1;
}
.table-data{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f0f2f4;
  font-size: 20px;
  font-weight: normal;
  color: #00204A;
}
.table-data:nth-child(2n){
  background: #fcfcfd;
}
.table-data > span{
  padding: 15px 0;
  flex: 2;
}
.table-data > span:first-child{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
}
.payment-type{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 39px;
}
.payment-in{
  color: #00B7C2;
}
.payment-out{
  color: #FE4747;
}
.custom-modal-background{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1055;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}
.custom-modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 26px 33px;
  gap: 24px;
  border: 1px solid #75757521;
  border-radius: 22px 22px 0 0;
  font-family: 'Nunito', sans-serif;
}
.custom-modal > .modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00204A;
  font-weight: bold;
  font-size: 24px;
}
.custom-modal > .modal-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: #00204A;
  gap: 34px;
}
.custom-modal > .modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.button-section{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.button-section > button{
  padding: 18px 32px;
  border: 1px solid #CACACA;
  border-radius: 5px;
  color: #95989A;
  background: transparent;
  flex: 1;
}
.amount-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount-section > button{
  padding: 20px 21px;
  border: 1px solid #CACACA;
  border-radius: 5px;
  background: transparent;
  color: #95989A;
}
.amount-section > div{
  position: relative;
}
.amount-section > div > .amount-input{
  font-size: 24px;
  font-weight: normal;
  color: #95989A;
  border: none;
  outline: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.amount-section > div > .amount-input::-webkit-outer-spin-button,
.amount-section > div > .amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.amount-section > div > #input-currency{
  position: absolute;
  font-size: 24px;
  font-weight: normal;
  color: #95989A;
  top: 50%;
  transform: translate(-100%, -50%);
  left: calc(50% + 65px);
}
.card-header{
  background: #fff;
  padding: 21px 49px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.card-body{
  padding: 0;
}
.card-row{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.card-row:nth-child(odd){
  background: #FBF4EE;
}
.card-icon{
  margin: 16px 8px 16px 54px;
}
.card-text > span{
  font-size: 17px;
  color: #00204a;
}
.card-option{
  margin-left: auto;
  margin-right: 16px;
}
.card-option > button{
  border: 0;
  background: transparent;
}
.card-option > button > i{
  color: #ff0000;
}
.add-card{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #00204a;
  height: 60px;
}
.add-card > span.plus-icon{
  margin-left: 44px;
  margin-right: 15px;
}
.add-card > span.text{
  font-size: 20px;
}
hr{
  margin: 0;
}
.wallet-history-title{
  font-size: 24px;
  font-weight: bold;
  color: #00204a;
}
.wallet-history-list{
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
}
.wallet-history-row{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
}
.wallet-history-row > div > h2{
  margin: 0;
}
.payment-method-title{
  font-size: 24px;
  font-weight: bold;
  color: #00204a;
}
.payment-method-form{
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.payment-method-form input{
  border: 1px solid #00B7C2;
  border-radius: 6px;
  padding: 16px;
}
.payment-method-divide{
  display: flex;
  gap: 12px;
}
.payment-method-divide > input{
  flex-basis: 50%;
}
.payment-method-secure{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
}
.payment-method-secure-icon{
  color: #00AB5C;
}
.payment-method-secure-text{
  color: rgba(51, 69, 93, 0.48);
}

</style>
