<template>
  <div id="peyk_bodynav">
    <!-- header -->
    <div class="container-fluid pb-2 ps-4 pt-5">
      <div class="row pt-3">
        <div class="col-12">
          <h1 class="title">Add New Favorites</h1>
        </div>
      </div>
      <hr class="me-3">
    </div>
    <!-- /header -->

    <div class="container-fluid ps-4">
      <div class="row">
        <!-- left box --->
        <div class="col-md-5 mb-5">
          <div class="card card-favourite-peyk p-3">
            <div class="row pb-3">
              <div class="col-12">
                <label  class="d-block text-info fw-bold fs-4">{{ form.type }}</label>

                  <button
                      type="button"
                      class="btn icon-favourite-button me-2"
                      :class="{active: form.type === 'Home'}"
                      @click="setType('Home')"
                  >
                    <img src="@/assets/images/icons/Home.png" alt="home">
                  </button>

                  <button
                      type="button"
                      class="btn icon-favourite-button me-2"
                      :class="{active: form.type === 'Work'}"
                      @click="setType('Work')"
                  >
                    <img src="@/assets/images/icons/work-2.png" alt="work">
                  </button>

                  <button
                      type="button"
                      class="btn icon-favourite-button"
                      :class="{active: form.type === 'Other'}"
                      @click="setType('Other')"
                  >
                    <img src="@/assets/images/icons/location.png" alt="location">
                  </button>

              </div>
            </div>

            <form class="row g-3" @submit.prevent="submit">

              <div class="zone-line mb-3">
                <div class="form-floating">
                  <input placeholder="Flat" type="text" v-model="info.building_no" class="form-control peyk-input">
                  <label for="">Building Number</label>
                </div>

                <div class="row mt-2">
                  <div class="col-6">
                    <div class="form-floating">
                      <input placeholder="Flat" type="text" v-model="info.zone_no" class="form-control peyk-input">
                      <label for="">Zone</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-floating">
                      <input placeholder="Flat" type="text" v-model="info.street_no" class="form-control peyk-input">
                      <label for="">Street</label>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button class="btn btn-info mt-2" @click="updataLocationBlueBoard">Find</button>
                </div>
              </div>
              <!-- google search address  -->
              <h6 class="title-google mt-4"> Search by Google Address</h6>
               <SearchAddress
                      class="col-12"
                      field="address"
                      :form="form"
                      :vuelidate="v$"
                      :caption="searchCaption"
                      @locationChanged="updateLocation"
                  />
              <!-- google search address  -->
              <!-- google search link  -->
               <h6 class="title-google"> Search by Google link</h6>
                  <div class="input-group mb-3">
                    <map-link 
                    field="address"
                      :UrlInput="valueinput"
                      @UrlInput="getInputValue"
                      @locationChanged="updateLocation"
                    />
                    <button class="input-group-text material-icons" @click.prevent="googleUrl">
                    search
                 </button>
                </div>
              <!-- google search link  -->
             <Input class="col-12"
                 :form="form"
                 :vuelidate="v$"
                 field="address"
              />

              <Input class="col-12"
                :form="form"
                :vuelidate="v$"
                field="name"
              />
              <Input class="col-12 col-lg-12"
                :form="form"
                :vuelidate="v$"
                field="description"
              />
              <SubmitButton class="col-6"
                            :form="form"
                            :vuelidate="v$"
              />
            </form>
          </div>
        </div>
        <!-- /left box -->

        <!-- map box   --->
        <div class="col-md-7 mb-5">
          <div class="map-peyk-favourite mx-md-3">
            <GoogleMap
              :center="center"
              :geolocationEnabled="false"
              :markers="markers"
            />
          </div>

        </div>
        <!-- /map box   -->
      </div>
    </div>

  </div>

</template>

<script>
import { reverseGeocode } from '@/services/geocoder';
import { GoogleMap } from '@/components';
import { Input, SubmitButton  } from '@/components/forms';
import { Favorite } from '@/forms'
import { favorites } from "@/api";
import {toaster} from "@/common/helpers";
import {
  SearchAddress,
} from '@/components/forms';
import MapLink from '@/components/forms/mapLink.vue';
export default {
  name: "AddFavorite",

  components: {
    GoogleMap,
    Input,
    SubmitButton,
    SearchAddress,
    MapLink
  },
  props: {
    searchCaption :{
      type: String,
      default: 'Search Dropoff Address',
    }
  },
  data() {
    return {
      info :{
        zone_no: null,
        street_no: null,
        building_no:null ,
      },
      center: null,
      markers: [],
      valueinput:'',
    }
  },

  setup() {
    const form = Favorite();
    const { v$ } = form;

    return { form, v$ };
  },

  methods: {
    async submit() {
      if (! this.v$.$invalid) {
        const status = await this.form.submit();

        if (status) {
          this.form.reset();
          this.v$.$reset();
          this.$router.push('/favorites');
        }
      }
    },

    async updateLocation(location) {
      this.form.coordinate = `${location.lat},${location.lng}`;

      this.center = location;

      this.markers = [{
        position: location,
        url: require("@/assets/images/icons/marker.svg"),
      }];

      await this.reverseGeocode(location);
    },

    async updataLocationBlueBoard(){
      let result = await favorites.callBlueBoardApi(this.info);
      let {features} = result.data;

      if(!features){
        return;
      }

      if (!features[0]?.geometry){
        toaster().error("Invalid Location !!!")
        return false;
      }

      let { x,y } = features[0].geometry;

      this.form.coordinate = `${y},${x}`;

      let location = {
        lat : y,
        lng : x
      };

      this.center = location;

      this.markers = [{
        position : location,
        url: require("@/assets/images/icons/marker.svg"),
      }]

      this.reverseGeocode(location);
    },

    async reverseGeocode({ lat, lng }) {
      const { address } = await reverseGeocode(lat, lng);
      this.form.address = address;
    },

    setType(type) {
      this.form.type = type;
    },
    getInputValue(value) { 
    this.valueinput=value; 
    }, 
    googleUrl(){
        let point = this.getLocationFromGoogleMapLink(this.valueinput);

        let lat= parseFloat(point[0]);
        let lng= parseFloat(point[1]);

        this.form.coordinate = `${lat},${lng}`;

      let location = {
        lat : lat,
        lng : lng,
      };

      this.center = location;

      this.markers = [{
        position : location,
        url: require("@/assets/images/icons/marker.svg"),
      }]

    this.reverseGeocode(location);
    },
    getLocationFromGoogleMapLink(link) {
        if((link.indexOf("https://www.google.com/maps/") >= 0) &&
            (link.indexOf("!8m2!3d") >= 0)) {

            link = link.split("!8m2!3d")[1];
            link = link.replace("?hl=en", "");
            link = link.split("!4d");

            if(link.length > 1) {
                return link;
            }
        } else if(link.indexOf("!3m") >= 0){
          link = link.split("!3m")[1];
            link = link.replace("?hl=en", "");
            link = link.split("!4d");

            if(link.length > 1) {
                return link;
            }
        }
        
        if((link.indexOf("https://www.google.com/maps/") >= 0) &&
            (link.indexOf("@") >= 0)) {

            link = link.split("@")[1];
            link = link.split(",");

            if(link.length > 1) {
                return [link[0], link[1]];
            }
        }


        return undefined;
    },
  },
}
</script>

<style scoped>
#peyk_bodynav {
  padding-left: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: white;
}
.title-google{
  color: #001f4a;
}
</style>

<style scoped src="@/assets/css/add-favorites.css"></style>
