import Form from '@/services/form';

import {
  required,
  minLength,
} from '@vuelidate/validators';


const data = {
  first_name: null,
  last_name: null,
  mobile: null,
  password: null,
  user_role: 'individual',
}

const rules = {
  first_name: {
    required,
  },
  last_name: {
    required,
  },
  mobile: {
    required,
    tel: null,
  },
  password: {
    required,
    minlength: minLength(6)
  },
}

const form = () => new Form(data, rules, 'auth/register');

export default form;
