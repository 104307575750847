<template>
  <SideMenu v-if="isLoggedIn"/>

  <router-view v-slot="{ Component, route }" >
    <transition :name="route.meta.transition">
      <component :is="Component" @click="toggleMenu"/>
    </transition>
  </router-view>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import SideMenu from "@/views/layouts/SideMenu";

import "@/assets/css/bootstrap.min.css"
import "popper.js"
import "bootstrap"

import "@/assets/css/main.css"
import "@/assets/css/default-pages.css"

export default {
  components: {
    SideMenu,
  },

  computed: {
    ...mapState(['isActiveMenu', 'location']),
    ...mapGetters('auth', ['isLoggedIn']),
  },

  async mounted() {
    this.setIsLoading(false);
    if (this.isLoggedIn){
      await this.getUserSettings(this.location);
    }
  },

  methods: {
    ...mapActions(['changeMenuState', 'getUserSettings']),
    ...mapMutations(['setIsLoading']),
    toggleMenu() {
      if (this.isActiveMenu){
        this.changeMenuState( ! this.isActiveMenu )
      }
    }
  },
}
</script>


<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
</style>


<style scoped>
.fade-leave-active {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.35s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}

.scale-enter-active,
.scale-leave-active {
  transition: all .3s ease-out;
}

.scale-enter-from,
.scale-leave-to {
  opacity: .3;
  transform: scale(1.05);
}
</style>
