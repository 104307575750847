import Form from '@/services/form';

import { required } from '@vuelidate/validators';

const data = {
  mobile: null,
  user_role: 'user', // business or user
}

const rules = {
  mobile: {
    required,
    tel: null,
  },
}

export default () => new Form(data, rules, 'auth/forgotPassword');
