<template>
  <div class="form-floating form-floating-peyk">
    <input
      :value="value"
      :placeholder="placeholder || name$"
      :type="type"
      :maxlength="maxLength"
      @input="updateValue"
      @keydown.delete="deleteKeyPressed"
      class="form-control form-control-peyk peyk-input"
    >

    <InputError
      :form="form"
      :fieldVuelidate="v$"
      :errors="e$"
      :field="field"
      :name="name$"
    />
  </div>
</template>

<script>
import InputError from './InputError.vue';
import { inputProps, inputComputed } from './InputAttributes';

export default {
  name: 'MaskInput',

  components: {
    InputError,
  },

  props: {
    ...inputProps,

    mask: {
      type: String,
      default: '*',
    },

    length: {
      type: Number,
      default: 6,
    }
  },

  computed: {
    ...inputComputed,

    value() {
      const value = this.form.get(this.field) || '';

      return value.padEnd(this.length, this.mask);
    },

    maxLength() {
      return this.value.includes('*') ? this.length + 1 : this.length;
    },

    fieldValue() {
      return this.form.get(this.field);
    },
  },

  methods: {
    updateValue(e) {
      const maxLengthFulfilled = this.value.replace(/\*/g, '').length >= this.length;
      let value = e.target.value || '';

      const trimLastChar = value.length < this.length && !maxLengthFulfilled;

      value = value.replace(/\*/g, '')
        .slice(0, trimLastChar ? -1 : Math.min(value.length, this.length));

      this.form.set(this.field, value);

      if (this.v$) {
        this.v$.$touch();

        this.e$.clear(this.field);
      }
    },

    deleteKeyPressed(e) {
      if (!this.fieldValue) {
        e.preventDefault();
      }
    }
  },
}
</script>

<style scoped>

input {
  letter-spacing: 42px;
  caret-color: transparent;
  font-size: 24px;
  text-align: center;
  padding-right: 0 !important;
  padding-left: 42px !important;
}

</style>
