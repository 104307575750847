<template>
  <div class="home">
    <img alt="Vue logo" src="../../assets/logo.png">
    <button class="btn btn-primary" @click="click1">Click {{fname }}</button>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data(){
    return {
      fname:'Peyk Co.'
    }
  },

  methods:{
    click1(){
      alert('hello world');
    }
  }
}
</script>
