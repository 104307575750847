import { reactive } from "vue";
import store from "@/store";
import Validator from "./validator";

export default class Form {
  /**
   * Create a new Form instance.
   *
   * @param {object} data
   * @param {object} rules
   * @param {string} action
   */
  constructor(data, rules, action) {
    this.originalData = JSON.parse(JSON.stringify(data));

    this.data = reactive(JSON.parse(JSON.stringify(data)));

    this.validator = new Validator(rules, this.data);
    this.v$ = this.validator.v$;
    this.action = action;

    return this.proxy();
  }

  /**
   * Create to proxy to dynamically assign the data fields into root form object.
   */
  proxy() {
    return new Proxy(this, {
      get: (target, field) =>
        field in target ? target[field] : target.data[field],

      set: (target, field, value) => {
        if (field in target) {
          target[field] = value;
        } else {
          target.data[field] = value;
        }

        return true;
      },
    });
  }

  /**
   * Reset the form fields.
   */
  reset() {
    for (let field in this.originalData) {
      this.data[field] = this.originalData[field];
    }

    this.validator.errors.clear();
  }

  /**
   * Return the value of the given field.
   * @param {string} field
   * @return {any}
   */
  get(field) {
    return this.data[field];
  }

  /**
   * Set the value of the given field.
   * @param {string} field
   * @param {any} value
   * @return {void}
   */
  set(field, value) {
    this.data[field] = value;
  }

  /**
   * Determine the form has any server error.
   * @return {boolean}
   */
  get $invalid() {
    return this.validator.errors.any();
  }

  /**
   * Submit the form.
   *
   * @param {Promise} [action]
   */
  async submit() {
    try {
      return this.action
        ? await store.dispatch(this.action, this.data)
        : await Promise.resolve(true);
    } catch (error) {
      this.validator.errors.record(error?.data?.errors);

      return error;
    }
  }

  /**
   * Assign the given object into form.
   *
   * @param {Object} data
   * @param {bool} skipNulls
   */
  assign(data, skipNulls = false) {
    for (let field in this.data) {
      if (skipNulls && data[field] === null) {
        continue;
      }

      this.data[field] = data[field];
    }
  }
}
