<template>
  <div class="accordion-item accordion-item-peyk ">
    <h2 class="accordion-header"  @click="toggleBody">
      <a
        class="accordion-button accordion-button-peyk collapsed pe-0"
        type="button"
        data-bs-toggle="collapse"
      >
        <slot name="header"></slot>
      </a>
    </h2>

    <SlideDown>
      <div class="accordion-collapse collapse show"
        v-show="modelValue"
      >
        <div class="accordion-body">
          <slot></slot>
        </div>
      </div>
    </SlideDown>
  </div>
</template>

<script>
import { SlideDown } from './transitions';

export default {
  name: 'Collapse',

  components: {
    SlideDown,
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggleBody() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
}
</script>

<style>

</style>
