<template>
  <Fade>
    <template v-if="modelValue">
      <div class="modal fade"
        v-focus-trap
        :class="{show: modelValue, 'modal-open': modelValue}"
        @click="close"
        @keydown.esc="close"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog" @click.stop="">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title">
                <slot name="header"></slot>
              </h5>
              <button type="button" class="btn-close me-1" @click="close"></button>
            </div>

            <div class="modal-body">
              <slot></slot>
            </div>

            <div class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Fade>

  <Fade>
    <div class="modal-backdrop show" v-if="modelValue"></div>
  </Fade>
</template>

<script>
import { Fade } from './transitions';

export default {
  name: 'Modal',

  components: {
    Fade,
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    close() {
      this.$emit('update:modelValue', false);
    },
  },
}
</script>

<style scoped>

.modal-title {
  color: var(--color-primary);
}

.modal-open {
  display: block !important;
}

</style>
