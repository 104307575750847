<template>
  <div class="card-item">
    <div class="card-type">
      <span :class="['icon', value.type]"></span>
    </div>
    <p class="item-text fs-4">{{ value.card_number }}</p>
    <div class="actions pt-2">
      <span class="fs-3 btn-remove" @click="removeCard(value.id)">
        <i class="bi bi-trash "></i>
      </span>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Card",
  props: ["value"],
  methods: {
    ...mapActions('cards', ['removeCard']),
  }
}
</script>

<style scoped>
.card-item {
  padding: 3px 10px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.card-item:hover {
  background: #f6f6f6;
}

.card-item > .card-type {
  flex: 10%;
}

.item-text {
  flex: 80%;
  letter-spacing: .21em;
}

.btn-remove {
  flex: 10%;
  right: 0;
  border: none;
  padding: 0;
  transition: all .3s;
}

.btn-remove:hover {
  transform: rotateY(180deg);
  cursor: pointer;
  color: #287aff;
}

.icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  background-size: 48px;
  background-repeat: no-repeat;
  background-position: center;
}

.icon.mastercard {
  background-image: url(../assets/images/icons/mastercard.svg);
}

.icon.visa {
  background-image: url(../assets/images/icons/visa.svg);
}
</style>
