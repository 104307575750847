<template>
  <div class="row pt-3 m-0">
    <div class="col-6 peyk-col-title" :class="{'underline' : !noLine}">
      <h1 class="peyk-page-title" :class="{'underline' : !noLine}">{{ title }}</h1>
    </div>
    <div class="order-title col-6">
      <router-link to="/new-order">
        <a class="add-new-order mb-0">Add New Order</a>
      </router-link>
      <button class="add-new-order-button">
        <img src="@/assets/images/icons/plus-small.png" class="add"/>
      </button>
    </div>
  </div>
  <hr class="peyk-hr-style"/>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    noLine: {
      Type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped>
.add {
  width: 100%;
}
.order-title{
  right: unset;
  position: unset;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
