<template>
  <div class="d-grid gap-2">
    <a class="cancel-button" type="button" :class="{ disabled : step > 1 }" @click.prevent="cancelModalVisible = true">
      Cancel Order
    </a>
  </div>

  <div class="modal fade" id="cancel-order-modal" aria-hidden="true" aria-labelledby="..." tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalToggleLabel1">May we know why?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="cancel-form">
            <div>
              <input id="radio-1" class="radio-custom" name="radio-group" type="radio"
                     value="Driver was too far" v-model="dataForm.type">
              <label for="radio-1" class="radio-custom-label p-2">Driver was too far </label>
            </div>
            <div>
              <input id="radio-2" class="radio-custom" name="radio-group" type="radio"
                     value="Driver was not moving" v-model="dataForm.type">
              <label for="radio-2" class="radio-custom-label p-2">Driver was not moving </label>
            </div>
            <div>
              <input id="radio-3" class="radio-custom" name="radio-group" type="radio"
                     value="Have changed my mind" v-model="dataForm.type">
              <label for="radio-3" class="radio-custom-label p-2">Have changed my mind </label>
            </div>
            <div>
              <input id="radio-4" class="radio-custom" name="radio-group" type="radio"
                     value="I will place an order later" v-model="dataForm.type">
              <label for="radio-4" class="radio-custom-label p-2">I will place an order later</label>
            </div>
            <div>
              <input id="radio-5" class="radio-custom" name="radio-group" type="radio"
                     value="other" v-model="dataForm.type">
              <label for="radio-5" class="radio-custom-label p-2">Other</label>
            </div>
          </div>
          <div class="form-floating mt-3">

              <textarea class="form-control" rows="4" v-model="dataForm.description" placeholder="Comment here"
                        maxlength="150"
                        id="floatingTextarea"></textarea>
            <label for="floatingTextarea">Description</label>
          </div>

        </div>
        <div class="modal-footer">
          <!-- Toogle to second dialog -->
          <button class="btn btn-primary btn-modal " :class="{ disabled : btnDisabled }"
                  data-bs-target="#cancel-order-modal"
                  @click="$emit('cancelOrderAction' ,{ order_id: dataForm.order_id, description: dataForm.type === 'other' ? dataForm.description : dataForm.type })"
                  data-bs-toggle="modal" data-bs-dismiss="cancel-order-modal">Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
  <Fade>
    <div class="custom-modal-background" @click.self="cancelModalVisible = false;" v-if="cancelModalVisible">
      <div class="custom-modal">
        <div class="modal-header">
          <span>May we know why?</span>
        </div>
        <div class="modal-body">
          <div class="cancel-form">
            <div class="custom-input-group">
              <input id="radio-1" class="radio-custom" name="radio-group" type="radio"
                     value="Driver was too far" v-model="dataForm.type">
              <span class="custom-radio" :class="{active: dataForm.type === 'Driver was too far'}"
                    @click.prevent="dataForm.type = 'Driver was too far'">
                <img src="@/assets/images/icons/check.png" alt="">
              </span>
              <label for="radio-1" class="radio-custom-label">Driver was too far </label>
            </div>
            <div class="custom-input-group">
              <input id="radio-2" class="radio-custom" name="radio-group" type="radio"
                     value="Driver was not moving" v-model="dataForm.type">
              <span class="custom-radio" :class="{active: dataForm.type === 'Driver was not moving'}"
                    @click.prevent="dataForm.type = 'Driver was not moving'">
                <img src="@/assets/images/icons/check.png" alt="">
              </span>
              <label for="radio-2" class="radio-custom-label">Driver was not moving </label>
            </div>
            <div class="custom-input-group">
              <input id="radio-3" class="radio-custom" name="radio-group" type="radio"
                     value="Have changed my mind" v-model="dataForm.type">
              <span class="custom-radio" :class="{active: dataForm.type === 'Have changed my mind'}"
                    @click.prevent="dataForm.type = 'Have changed my mind'">
                <img src="@/assets/images/icons/check.png" alt="">
              </span>
              <label for="radio-3" class="radio-custom-label">Have changed my mind </label>
            </div>
            <div class="custom-input-group">
              <input id="radio-4" class="radio-custom" name="radio-group" type="radio"
                     value="I will place an order later" v-model="dataForm.type">
              <span class="custom-radio" :class="{active: dataForm.type === 'I will place an order later'}"
                    @click.prevent="dataForm.type = 'I will place an order later'">
                <img src="@/assets/images/icons/check.png" alt="">
              </span>
              <label for="radio-4" class="radio-custom-label">I will place an order later</label>
            </div>
            <div class="custom-input-group">
              <input id="radio-5" class="radio-custom" name="radio-group" type="radio"
                     value="other" v-model="dataForm.type">
              <span class="custom-radio" :class="{active: dataForm.type === 'other'}"
                    @click.prevent="dataForm.type = 'other'">
                <img src="@/assets/images/icons/check.png" alt="">
              </span>
              <label for="radio-5" class="radio-custom-label">Other</label>
            </div>
          </div>
          <div class="form-floating">
            <textarea class="form-control" rows="4" v-model="dataForm.description" placeholder="Description"
                      maxlength="150"
                      id="floatingTextarea"></textarea>
            <label for="floatingTextarea">Description</label>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-modal " :class="{ disabled : btnDisabled }"
                  @click="confirmCancel">Confirm
          </button>
        </div>
      </div>
    </div>
  </Fade>


</template>


<script>
import {Fade} from "@/components/transitions";
export default {
  name: "CancelOrder",
  props: ["step"],
  emits: ['cancelOrderAction'],
  components: {
    Fade,
  },
  data() {
    return {
      dataForm: {
        type: "",
        description: "",
      },
      cancelModalVisible: false,
    }
  },
  computed: {
    btnDisabled() {
      return !this.dataForm.type || (this.dataForm.type == "other" && this.dataForm.description.length < 5);
    }
  },
  methods: {
    confirmCancel() {
      this.$emit('cancelOrderAction', {
        order_id: this.dataForm.order_id,
        description: this.dataForm.type === 'other' ? this.dataForm.description : this.dataForm.type
      })
      this.cancelModalVisible = false;
    }
  }
}
</script>
<style scoped>
.custom-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1055;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}

.custom-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 26px 33px;
  gap: 24px;
  border: 1px solid #75757521;
  border-radius: 22px 22px 0 0;
  font-family: Nunito, sans-serif;
}

.custom-modal > .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal > .modal-header > span {
  font-family: Nunito, sans-serif;
  color: #00204A;
  font-weight: bold;
  font-size: 24px;
}

.custom-modal > .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;
  color: #00204A;
  font-size: 16px;
  font-weight: normal;
}

.custom-modal > .modal-body label{
  font-family: Nunito, sans-serif;
}

.custom-modal > .modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.custom-modal > .modal-footer > button {
  flex-grow: 1;
  background: #00B7C2;
  border-radius: 5px;
  padding: 14px 0;
  color: #fff;
  font-size: 20px;
  border: none;
  outline: none;
}
</style>
<style scoped>
.cancel-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;
}

.cancel-form > .custom-input-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.cancel-form > .custom-input-group > label {
  cursor: pointer;
}

.cancel-form > .custom-input-group > input[type='radio'] {
  display: none;
}

.cancel-form > .custom-input-group > .custom-radio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border: 1px solid #C9C9C9;
  border-radius: 4px;
  cursor: pointer;
  color: #FFFFFF;
}

.cancel-form > .custom-input-group > .custom-radio > img {
  width: 10px;
}

.cancel-form > .custom-input-group > .custom-radio.active {
  background: #87DF6D;
}

.form-floating > textarea{
  min-height: 100px;
}

.cancel-button {
  width: 100%;
  text-align: center;
  padding: 18px 0;
  background: #00204A;
  border-radius: 5px;
  color: #FFF;
  font-family: Nunito, sans-serif;
  font-weight: normal;
  font-size: 18px;
}
</style>