<template>
  <div id="peyk_bodynav" class="container-fluid">
    <PageTitle :noLine="true" title="Profile"/>
    <div class="profile-container">
      <div class="tab-selector" v-if="isBusinessUser">
        <span ref="profileTab" class="selected" @click.prevent="changeTab('profile')">Profile</span>
        <span ref="apiTab" @click.prevent="changeTab('api')">API Tokens</span>
      </div>
      <div class="input-container" :class="{'ml': !isBusinessUser}" v-if="profileTab">
        <h3 class="input-text">Profile edit</h3>
        <template v-if="!isBusinessUser">
          <CustomInput v-focus :form="form" :vuelidate="v$" field="first_name" name="First Name"/>
          <CustomInput :form="form" :vuelidate="v$" field="last_name" name="Last Name"/>
        </template>
        <CustomInput v-else :form="form" :vuelidate="v$" field="business_name" name="Business Name"/>
        <CustomInput :form="form" :vuelidate="v$" field="email" name="Email"/>
        <div class="custom-input-group">
          <label for="">Username</label>
          <TelInput
              class="col-12"
              :form="form"
              :vuelidate="v$"
              field="phone"
              :auto-country="true"
              :show-no-error="false"
          />
        </div>
        <div class="fake-input-group">
          <label for="">Password</label>
          <div class="fake-input">
            * * * * * *
          </div>
          <button class="password-button" @click.prevent="passwordModalVisible = true">
            <span><i class="bi bi-shield-lock"></i></span>
            <span>Change password</span>
          </button>
        </div>
        <div class="business-section" v-if="true">
          <div class="industry" @click.prevent="openDropdown">
            <span>Industry</span>
            <span class="icon"><img src="@/assets/images/icons/angle-left.png" alt="angle-left"></span>
            <div class="industry-dropdown">
              <span class="dropdown-title">Industry</span>
              <ul>
                <li @click.stop="selectDropdown">Fast food</li>
                <li @click.stop="selectDropdown">Clothes</li>
                <li @click.stop="selectDropdown">Restaurants</li>
                <li @click.stop="selectDropdown">Fast food</li>
                <li @click.stop="selectDropdown">Clothes</li>
                <li @click.stop="selectDropdown">Restaurants</li>
              </ul>
            </div>
          </div>
          <div class="delivery" @click.prevent="openDropdown">
            <span>Monthly delivery</span>
            <span class="icon"><img src="@/assets/images/icons/angle-left.png" alt="angle-left"></span>
            <div class="delivery-dropdown">
              <span class="dropdown-title">Monthly delivery</span>
              <ul>
                <li @click.stop="selectDropdown">Fast food</li>
                <li @click.stop="selectDropdown">Clothes</li>
                <li @click.stop="selectDropdown">Restaurants</li>
                <li @click.stop="selectDropdown">Fast food</li>
                <li @click.stop="selectDropdown">Clothes</li>
                <li @click.stop="selectDropdown">Restaurants</li>
              </ul>
            </div>
          </div>
        </div>
        <SubmitButton :form="form" :vuelidate="v$">Update profile</SubmitButton>
      </div>
      <div class="api-tab" v-else>
        <div class="input-group">
          <input type="text" v-model="tokenName" placeholder="Name for API token">
          <button class="btn btn-primary-peyk" @click.prevent="submitToken" :disabled="isLoading">Generate Token</button>
          <button class="btn btn-primary-peyk">API Document</button>
        </div>
        <span class="subtitle">* API tokens will be valid for a period of one (1) year.</span>
        <table>
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Expiry Date</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="tokens.length">
            <tr v-for="(token, index) in tokens" :key="token.id" :class="{'expired': token.is_expired}">
              <td>{{++index}}</td>
              <td>{{token.name}}</td>
              <td>{{new Date(token.expires_at).toLocaleDateString()}}</td>
              <td><button class="btn btn-danger" @click="openDeletePopup(token.id)" :disabled="isLoading"><span><i class="bi bi-trash"></i></span> Delete</button></td>
            </tr>
          </template>
          <tr v-else>
            <td></td>
            <td>Nothing to show.</td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation" v-if="paginateInfo.last_page > 1">
          <ul class="pagination justify-content-center align-items-center">
            <li class="page-item" :class="{'disabled': paginateInfo.current_page === 1}"><button class="page-link" @click="changePage(paginateInfo.current_page - 1)" :disabled="paginateInfo.current_page === 1">Previous</button></li>
            <li class="page-item" :class="{'active': paginateInfo.current_page === page + 1}" v-for="page in pages" :key="page"><button class="page-link" @click="changePage(page + 1)">{{page + 1}}</button></li>
            <li class="page-item" :class="{'disabled': paginateInfo.current_page === paginateInfo.last_page}"><button class="page-link" @click="changePage(paginateInfo.current_page + 1)" :disabled="paginateInfo.current_page === paginateInfo.last_page">Next</button></li>
          </ul>
        </nav>
      </div>
    </div>
    <Fade>
      <div class="custom-modal-background" @click.self="passwordModalVisible = false;" v-if="passwordModalVisible">
        <div class="custom-modal">
          <div class="modal-header">
            <span>Change Password</span>
          </div>
          <div class="modal-body">
            <PasswordInput
                :form="passwordForm"
                :vuelidate="pv$"
                field="password_current"
                name="Current Password"
                placeholder="Enter your current password"
                type="password"
            />

            <PasswordInput
                :form="passwordForm"
                :vuelidate="pv$"
                field="password"
                name="Password"
                placeholder="Enter your new password"
                type="password"
            />

            <PasswordInput
                :form="passwordForm"
                :vuelidate="pv$"
                field="password_confirmation"
                name="Password Confirmation"
                placeholder="Confirm your new password"
                type="password"
            />
          </div>
          <div class="modal-footer">
            <SubmitButton :form="passwordForm" :vuelidate="pv$" :canSubmit="true" @submitted="closePasswordModal" style="width: 100%"/>
          </div>
        </div>
      </div>
    </Fade>
    <Fade v-if="isBusinessUser">
      <div class="custom-modal-background" @click.self="tokenModalVisible = false;" v-if="tokenModalVisible">
        <div class="custom-modal">
          <div class="modal-header">
            <span>Your API token is</span>
          </div>
          <div class="modal-body token-modal">
            <div class="input-group">
              <input ref="tokenInput" :value="tokenModel" type="text">
              <button @click.prevent="copyToClipboard"><span><i class="bi bi-clipboard"></i></span></button>
            </div>
            <p>*This token will not be viewable again after closing the window</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary-peyk w-100" @click.prevent="tokenModalVisible = false;">Done</button>
          </div>
        </div>
      </div>
    </Fade>
    <Fade v-if="isBusinessUser">
      <div class="custom-modal-background" @click.self="deleteModalVisible = false;" v-if="deleteModalVisible">
        <div class="custom-modal">
          <div class="modal-header">
            <span>Are you sure?</span>
          </div>
          <div class="modal-footer delete-footer">
            <button class="btn btn-danger" @click.prevent="removeItem" :disabled="isLoading">Yes</button>
            <button class="btn btn-primary-peyk" @click.prevent="deleteModalVisible = false;">No</button>
          </div>
        </div>
      </div>
    </Fade>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import {mapActions, mapGetters, mapState} from 'vuex'
import { Profile, Password } from '@/forms';
import { SubmitButton, CustomInput, PasswordInput } from '@/components/forms';
import {TelInput} from "@/components/forms";
import {toaster} from "@/common/helpers";

export default {
  name: "Profile",

  components: {
    PageTitle,
    SubmitButton,
    TelInput,
    CustomInput,
    PasswordInput,
  },

  data() {
    return {
      passwordModalVisible: false,
      profileTab: true,
      tokenName: "",
      tokenModalVisible: false,
      deleteModalVisible: false,
      tokenModel: "",
      isLoading: false,
      deleteId: "",
    }
  },

  async mounted() {
    if (this.isBusinessUser){
      await this.getTokens();
    }
  },

  computed: {
    ...mapGetters('auth', ['isBusinessUser']),
    ...mapState({
      tokens: ({ business }) => business.tokens,
      paginateInfo: ({ business }) => business.paginateInfo,
    }),
    pages(){
      return [...Array(this.paginateInfo.last_page).keys()];
    }
  },

  setup() {
    const form = Profile();
    const { v$ } = form;

    const passwordForm = Password();
    const pv$ = passwordForm.v$;

    return {
      form,
      v$,

      passwordForm,
      pv$,
      ...passwordForm.validator.refs
    };
  },

  methods: {
    ...mapActions("business", ["getTokens", "removeToken", "addToken"]),
    async changePage(page){
      await this.getTokens(page)
    },
    openDeletePopup(id){
      this.deleteId = id;
      this.deleteModalVisible = true;
    },
    async removeItem(){
      try {
        this.isLoading = true;
        await this.removeToken(this.deleteId);
        // check if page is empty request for previous page otherwise request for current page
        await this.getTokens(this.tokens.length === 0 ? (this.paginateInfo.current_page === 1 ? this.paginateInfo.current_page : this.paginateInfo.current_page - 1) : this.paginateInfo.current_page);
      }catch (e){
        console.log(e);
      }finally {
        this.isLoading = false;
        this.deleteModalVisible = false;
      }
    },
    async submitToken(){
      try {
        this.isLoading = true;
        const data = await this.addToken({
          name: this.tokenName
        })
        this.tokenName = "";
        this.tokenModel = data.data.accessToken;
        this.tokenModalVisible = true;
      }catch (e){
        console.log(e);
      }finally {
        this.isLoading = false;
      }
    },
    closePasswordModal() {
      this.passwordModalVisible = false;
    },
    openDropdown(e){
      const element = e.path.find(items=>items.classList.contains("delivery") || items.classList.contains("industry"));
      if (element){
        element.classList.toggle("open");
      }
    },
    selectDropdown(e){
      const element = e.target;
      if (!element.classList.contains("selected")){
        element.parentElement.children.forEach(item => {
          item.classList.remove("selected");
        });
        element.classList.add("selected");
      }
    },
    changeTab(tab){
      if (tab === "profile"){
        this.$refs.apiTab.classList.remove("selected");
        this.$refs.profileTab.classList.add("selected");
        this.profileTab = true;
      }else {
        this.$refs.profileTab.classList.remove("selected");
        this.$refs.apiTab.classList.add("selected");
        this.profileTab = false;
      }
    },
    copyToClipboard(){
      // Get the text field
      const copyText = this.$refs.tokenInput;

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);

      // Alert the copied text
      toaster().success("API token copied to your clipboard");

      this.shareModalVisible = false;
    },
  },

  watch: {
    passwordModalVisible(val) {
      if (val){
        this.passwordForm.reset();
        this.pv$.$reset();
      }
    }
  },
}
</script>

<style scoped src="@/assets/css/profile.css"></style>
<style scoped>
.btn-danger:disabled{
  opacity: 0.5 !important;
}
.btn-primary-peyk:disabled{
  opacity: 0.5 !important;
  color: #fff !important;
  background-color: var(--color-info) !important;
}
.custom-modal > .modal-body.token-modal{
  gap: 1rem;
}
.custom-modal > .modal-body.token-modal > p{
  color: #b9b9b9;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  margin: 0 !important;
}
.custom-modal > .modal-body > .input-group{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.custom-modal > .modal-body > .input-group > input{
  width: 100%;
  border: 1px solid #ECECEC;
  border-radius: 5px !important;
  padding: 5px;
  font-family: Nunito, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #00204A;
}
.custom-modal > .modal-body > .input-group > button{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: none;
  background: white;
}
.custom-modal > .modal-footer.delete-footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.custom-modal > .modal-footer.delete-footer > *{
  flex: 1;
  padding: 13px 63px;
}
</style>
<style scoped>
.profile-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 41px;
}
.tab-selector{
  align-self: flex-start;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  width: 20%;
}
.tab-selector > span{
  font-family: 'Nunito', sans-serif;
  font-size: 28px;
  font-weight: normal;
  color: #00204A;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.tab-selector > span.selected{
  background: #f2f2f2;
}
.api-tab{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: 6rem;
  gap: 10px;
}
.api-tab > .input-group{
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2rem;
}
.api-tab > .input-group > input{
  flex: 1;
  border: 1px solid #e6e6e6;
  border-radius: 5px !important;
  outline: none;
  padding: 0 2rem;
  font-size: 18px;
}
.api-tab > .input-group > input::placeholder{
  color: #475e7c;
  font-size: 18px;
}
.api-tab > .input-group > button{
  border-radius: 10px !important;
  font-size: 18px;
}
.api-tab > .subtitle{
  color: #a6a6a6;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}
.api-tab > table{
  border: 1px solid #f6f6f6;
  color: #475e7c;
}
.api-tab > table > thead > tr > th, .api-tab > table > tbody > tr > td{
  font-family: 'Nunito', sans-serif;
  padding: 0.6rem;
}
.api-tab > table > thead > tr > th:first-child, .api-tab > table > tbody > tr > td:first-child{
  display: flex;
  justify-content: center;
  align-items: center;
}
.api-tab > table > thead > tr > th{
  font-size: 20px;
}
.api-tab > table > tbody > tr:nth-child(2n){
  background: #f0f2f4;
}
.api-tab > table > tbody > tr > td{
  font-size: 18px;
}
.api-tab > table > tbody > tr.expired > td:not(.api-tab > table > tbody > tr.expired > td:last-child){
  opacity: .5;
}
.api-tab > table > tbody > tr > td > button{
  border-radius: 10px;
}
.api-tab > table > tbody > tr > td:last-child{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.input-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  margin-right: 6rem;
  padding: 40px 37px 48px 30px;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  gap: 20px;
}
.input-container.ml{
  margin-left: 2rem;
}
.input-text{
  font-family: 'Nunito', sans-serif;
  font-size: 28px;
  font-weight: normal;
  color: #00204A;
}
.custom-input-group{
  position: relative;
  width: 100%;
}
.custom-input-group > label{
  position: absolute;
  top: 0;
  background: #fff;
  color: #B7B7B7;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  left: 20px;
  transform: translate(0, -50%);
  padding-left: 5px;
  padding-right: 10px;
  z-index: 2;
}
.custom-input-group:deep(.vue-tel-input){
  padding: 16px;
  width: 100%;
  outline: none;
  border: 2px solid #EAEAEA;
  border-radius: 5px;
  color: #00204A;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}
.custom-input-group:deep(.vue-tel-input:focus-within){
  border-color: #EAEAEA !important;
  box-shadow: none !important;
}
.custom-input-group:deep(.vti__selection){
  border-right: 2px solid #CACACA;
  padding-right: 10px;
}
.fake-input-group{
  position: relative;
  width: 100%;
}
.fake-input-group > label{
  position: absolute;
  top: 0;
  background: #fff;
  color: #B7B7B7;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  left: 20px;
  transform: translate(0, -50%);
  padding-left: 5px;
  padding-right: 10px;
}
.fake-input-group > .fake-input{
  width: 100%;
  outline: none;
  border: 2px solid #EAEAEA;
  border-radius: 5px;
  padding: 21px 20px 7px 20px;
  color: #00204A;
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
}
.password-button{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  background: transparent;
  outline: none;
  border: none;
  color: #032149;
  font-size: 14px;
}
.business-section{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
}
.industry, .delivery{
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F2F2F2;
  border-radius: 5px;
  color: #646464;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: normal;
  padding: 20px 23px 16px 25px;
  cursor: pointer;
}
.industry > .icon > img, .delivery > .icon > img{
  width: 21px;
  transition: all 200ms ease-in-out;
}
.industry.open > .icon > img, .delivery.open > .icon > img{
  transform: rotate(-90deg);
}
.industry > .industry-dropdown, .delivery > .delivery-dropdown{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 200ms linear;
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
  transform: translate(0, 100%);
  border-radius: 5px;
  background: #F2F2F2;
  padding-top: 16px;
  padding-bottom: 3px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  color: #373737;
}
.industry > .industry-dropdown > .dropdown-title, .delivery > .delivery-dropdown > .dropdown-title{
  display: block;
  padding-left: 16px;
  font-weight: 600;
  margin-bottom: 11px;
}
.industry.open > .industry-dropdown, .delivery.open > .delivery-dropdown{
  visibility: visible;
  opacity: 1;
}
.industry > .industry-dropdown > ul, .delivery > .delivery-dropdown > ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.industry > .industry-dropdown > ul > li, .delivery > .delivery-dropdown > ul > li{
  cursor: pointer;
  padding: 2px 36px;
}
.industry > .industry-dropdown > ul > li.selected, .delivery > .delivery-dropdown > ul > li.selected{
  background: #F2DDC8;
}
.custom-modal-background{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1055;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}
.custom-modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 26px 33px;
  gap: 24px;
  border: 1px solid #75757521;
  border-radius: 22px 22px 0 0;
  font-family: 'Nunito', sans-serif;
}
.custom-modal > .modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00204A;
  font-weight: bold;
  font-size: 24px;
}
.custom-modal > .modal-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: #00204A;
  gap: 26px;
}
.custom-modal > .modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
#peyk_bodynav {
  padding-left: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: white;
}
</style>
