import Form from "@/services/form";

import { required, minLength, maxLength, numeric } from "@vuelidate/validators";

const data = {
  type: "pickup",
  lat: null,
  long: null,
  name: null,
  phone: null,
  postcode: null,
  address: null,
};

const rules = {
  name: {
    required,
    minLength: minLength(4),
  },
  address: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(200),
  },
  phone: {
    required,
    tel: null,
  },
  flat: {
    numeric,
  },
};

export default () => new Form(data, rules);
