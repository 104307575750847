<template>
  <div class="order-status py-3">
    <div class="d-flex justify-content-center bd-highlight mb-3">

      <h4 class="p-2 bd-highlight item mr-4" :class="{active : orderData?.step == 1}">
        Accept
      </h4>

      <hr class="custom-hr">

      <h4 class="p-2 bd-highlight item mr-5" :class="{active : orderData?.step == 2}">
        Arrive
      </h4>

      <hr class="custom-hr">

      <h4 class="p-2 bd-highlight item mr-5" :class="{active : orderData?.step == 3}">
        PickUp
      </h4>

      <hr class="custom-hr">

      <template v-for="(receiver, index) in orderData?.receiver_info" :key="index">
        <h4 v-if="receiver?.address" class="p-2 bd-highlight item mr-5" :class="{ active: orderData?.step === index + 4 }">
          {{ 'Dropoff ' + (index + 1) }}
        </h4>
        <hr class="custom-hr">
      </template>

      <h4 class="p-2 bd-highlight item mr-5" :class="{active : orderData?.is_completed}">
        Complete
      </h4>

    </div>
  </div>
</template>

<script>

export default {
  name: "StepIndicator",
  props: ["orderData"],
  computed: {
    showDropoff1() {
      return this.orderData?.receiver_info[0]?.address;
    },
    showDropoff2() {
      return this.orderData?.receiver_info[1]?.address;
    },
    showDropoff3() {
      return this.orderData?.receiver_info[2]?.address;
    },
  },
}
</script>

<style scoped>
.item {
  width: 100px;
  height: 100px;
  border: 1px solid #e7e7e7;
  background: #ffffff;
  color: #CBCBCB;
  margin: 0 28px;
  font-size: 18px;
  font-family: BariolBold, serif;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  padding: 40px 5px 5px !important;
  z-index: 1;
}

.item h4 {
  padding-top: 40px;
}

.item:hover {
  box-shadow: 1px 2px 4px #a1a1a1;
  transition: all ease-in .23s;
}

.item.active {
  border: 2px solid #e7e7e7;
  background: #00B7C2;
  color: #ffffff;
  padding: 0 40px;
}

.custom-hr {
  background-color: transparent !important;
  border: 2px dashed #00204A;
  transform: rotate(90deg);
  opacity: 1;
}
</style>