import Form from "@/services/form";

import { required, minLength, maxLength } from "@vuelidate/validators";

const data = {
    id: null,
    name: null,
    type: "Home",
    coordinate: null,
    address: null,
    description: null,
};

const rules = {
    name: {
        required,
        minLength: minLength(4),
    },
    address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
    },
};

export default () => new Form(data, rules, "favorites/updateFavorite");
