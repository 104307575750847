import Api from "./api";

export default {
  getUserSettings(payload) {
    return Api().post("v1/user/setting", payload);
  },

  getCitySettings(payload) {
    return Api().post("v1/setting-city", payload);
  },
};
