<template>
  <div class="row">
    <div class="col-6">
      <p style="color: #33455d50">Select your Card</p>
    </div>

    <div class="col-6">
      <router-link :to="{ name: 'Payment' }" class="peyk-link d-flex align-items-center justify-content-end">
        <img src="@/assets/images/icons/plus.png" class="mx-1" />
        Add new Card
      </router-link>
    </div>

    <div class="col-12">
      <div class="card-box">
        <div class="form-check form-check-peykneworder p-3"
          v-for="card in cards"
          :key="card.id"
        >
          <label class="form-check-label" for="flexRadioDefault1">
            <img :src="card.icon" class="me-3" />
            <span>{{ card.card_number }}</span>
          </label>
          <input
            class="form-check-input"
            type="radio"
            name="card"
            :value="card.id"
            @input="$emit('changeCard', { card })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SelectCard',

    computed: {
      ...mapState('cards', ['cards']),
    },
}
</script>
