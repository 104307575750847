<template>
  <div class="form-floating ">
    <input
      :value="value"
      :placeholder="placeholder$"
      :type="type"
      @input="updateValue"
      class="form-control peyk-input custom-input"
    >
    <label for="label">{{ placeholder$ || name$ }}</label>

    <InputError
      :form="form"
      :fieldVuelidate="v$"
      :errors="e$"
      :field="field"
      :name="name$"
    />
  </div>
</template>

<script>
import InputError from './InputError.vue';
import { inputProps, inputComputed } from './InputAttributes';

export default {
  name: 'Input',

  components: {
    InputError,
  },

  props: inputProps,

  computed: inputComputed,

  methods: {
    updateValue(e) {
      this.form.set(this.field, e.target.value);

      if (this.v$) {
        this.v$.$touch();

        this.e$.clear(this.field);
      }
    },
  }

}
</script>
<style>
label{
  text-transform: capitalize;
}
.custom-input{
  border: 1px solid #CACACA;
}
</style>

