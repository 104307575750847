<template>
    <slot></slot>
</template>

<script>
export default {
  name: 'SlideDown'
}
</script>

<style scoped>

.relative {
  position: relative;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.35s ease;
  overflow: hidden;
}

.slide-down-enter-to,
.slide-down-leave-from {
  transform: translateY(0) scaleY(1);
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-50%) scaleY(0);
}
</style>
