<template>
  <div class="container-fluid">
    <div class="row set-max-height">
      <!-- ****************** left side bar ********************* -->
      <div class="col-md-6 order-md-first">
        <div class="row">
          <div class="col-md-8 offset-md-2 col-10 offset-1">
            <div class="header-form header-form-peyk">
              <img src="@/assets/images/icons/logo.png" class="mt-5 logo-img" />

              <h1 class="mt-4 pt-5 mt-4">Login</h1>
              <p class="my-3">Send anything to anywhere in minutes</p>
            </div>

            <div class="body-form mb-5 pb-5 mt-4">
              <div class="tab-content" id="myTabContent">
                <form class="" @submit.prevent="submit">
                  <TelInput
                    class="col-12 mb-3"
                    v-focus
                    :form="form"
                    :field="'mobile'"
                    :vuelidate="v$"
                  />

                  <Input
                    class="col-12 mb-3"
                    :form="form"
                    :field="'password'"
                    :type="'password'"
                    :vuelidate="v$"
                  />

                  <SubmitButton class="d-grid gap-2 mt-2"
                    :form="form"
                    :vuelidate="v$"
                  >Login</SubmitButton>

                </form>
              </div>

              <div class="d-flex justify-content-between">
                <div class="link-login-signup mt-3">
                  <p class="me-2">Don't have an account?</p>
                  <router-link to="/signup" class="v-link">Sign Up</router-link>
                </div>

                <div class="link-login-signup mt-3">
                    <router-link to="/forgot-password" class="v-link">Forgot password</router-link>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row login-footer">
          <div class="col-md-8 offset-md-2 col-10 offset-1">
            <div class="powered-by">
              Powered by
              <img src="@/assets/images/pic/logo.png" alt="Peyk">
            </div>
          </div>
        </div>
      </div>

      <!-- ****************** right side bar ********************* -->

      <div class="col-md-6 px-0 order-md-first order-first d-none d-sm-none d-md-block d-lg-block right-side">
        <figure class="login-individual-page border-position-pics" v-if="! isBusiness"></figure>
        <figure class="signup-business-page border-position-pics" v-else-if="isBusiness"></figure>
      </div>
    </div>
  </div>
</template>

<script>
import { Login } from '@/forms';
import {
  Input,
  TelInput,
  SubmitButton,
} from '@/components/forms';
import {toaster} from "@/common/helpers";
// import router from "@/router";

export default {
  name: 'Login',

  components: {
    Input,
    TelInput,
    SubmitButton,
  },

  data() {
    return {
      activeImage: "individual",
    };
  },

  setup() {
    const form = Login();
    const { v$ } = form;

    return {
      form,
      v$,
    }
  },

  computed: {
    isBusiness() {
      return this.activeImage === 'business';
    },
  },

  methods: {
    async submit() {
      if (! this.v$.$invalid) {
        try {
          const status = await this.form.submit();
          console.log("status====",status);

          if (status) {
            this.$router.push({name: 'NewOrder'});
            // window.location.replace(router.resolve({name: "NewOrder"}).href);
          }
        } catch (err) {
          toaster().info("Something went wrong, Please try again");
        }
      }
    },

    roleChanged(isBusiness) {
      this.activeImage = isBusiness ? 'business' : 'individual';

      this.form['user_role'] = isBusiness ? 'business' : 'user';
    }
  },
}
</script>
<style scoped>
.logo-img{
  width: 104px;
}
.login-footer{
  margin-top: 12%;
}
.powered-by{
  color: #00204A;
  font-family: 'Bariol', sans-serif;
  font-weight: bold;
  font-size: 16px;
}
.powered-by > img{
  width: 56px;
}
</style>