import { settings } from '@/api';

export default {
  async getUserSettings({ commit }, { lat, lng }) {
    commit('setLocation', { lat, lng });

    const { data } = await settings.getUserSettings({ lat, long: lng });

    commit('setCityId', data.data.city_id);
    commit('cards/setCards', data.data.cards);
    commit('setMapKey', data.data.google_map_api_key);
  },

  changeMenuState({commit}, payload) {
    commit('setStateActiveMenu', payload);
  },

  startLoading({ commit }) {
    commit('setIsLoading', true);
  },

  endLoading({ commit }) {
    commit('setIsLoading', false);
  },
}
