import {
  NotFound,
  ForceUpdate,
} from '@/views/pages/errors';

export default [
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/426',
    name: 'ForceUpdate',
    component: ForceUpdate,
  },
];
