<template>
  <Fade>
    <template v-if="modelValue">
      <div class="modal fade"
           v-focus-trap
           :class="{show: modelValue, 'modal-open': modelValue}"
           @click="close"
           @keydown.esc="close"
           role="dialog"
           tabindex="-1"
      >
        <div class="custom-modal" @click.stop="">
          <div class="custom-head">
            <slot name="header"></slot>
            <button type="button" class="btn-close me-1" @click="close"></button>
          </div>
          <div class="custom-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </template>
  </Fade>
  <Fade>
    <div class="modal-backdrop show" v-if="modelValue"></div>
  </Fade>
</template>

<script>
import { Fade } from './transitions';

export default {
  name: "BottomModal",
  components: {
    Fade,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    },
  },
}
</script>

<style>
.modal-open {
  display: block !important;
}
.custom-modal{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 486px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 14px 12px #44444414;
  border: 1px solid #75757521;
  border-radius: 22px 22px 0 0;
  opacity: 1;
}
.custom-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 23px;
}
</style>