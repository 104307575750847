<template>
  <h1>404 : Page Not Found! </h1>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
