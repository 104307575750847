import Form from "@/services/form";

const data = {
  discount_price: null,
  city_id: null,
  vehicle_id: null,
  is_return: false,
  discount_code: null,
  endpoint: "web",
  pickup_id:null,
  card_id: null,
  payment_intent_id: null,
  payment_type: null,
  schedule: null,
  addresses: [
    {
      building_no: null,
      zone_no: null,
      street_no: null,

      type: "pickup",
      lat: null,
      long: null,
      name: null,
      phone: null,
      postcode: null,
      address: null,
      description: null,
      collect: 0,
      deposit: 0,
    },
    {
      building_no: null,
      zone_no: null,
      street_no: null,
      label: "Where is your Drop off?",
      type: "dropoff",
      lat: null,
      long: null,
      name: null,
      phone: null,
      postcode: null,
      address: null,
      description: null,
      collect: 0,
      deposit: 0,
    },
  ],
};

const rules = {};

export default () => new Form(data, rules, "orders/orderSubmit");
