<template>
<div class="accordion-item mt-3">
    <h2 class="accordion-header" id="flush-headingTwo"  @click="showFavoriteButton=!showFavoriteButton">
        <a class="accordion-button collapsed pe-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            <div class="row w-100">
                <div class="col-md-8 col-lg-9 text-start pe-0 pt-1">
                    <img src="@/assets/images/icons/square.png" class="me-md-2 me-lg-3 ">
                    <span>Where is your Drop off</span>
                    <span class="d-none">NW10</span>
                </div>
                <div class="col-md-4 col-lg-3 px-0">
                    <!-- Button trigger modal -->
                    <button type="button" class="btn btn-fav" data-bs-toggle="modal" data-bs-target="#exampleModalfavouritesTwo" v-if="showFavoriteButton">
                        <img src="../assets/images/icons/heart.svg" alt="hart-favpurites" class="">
                        <span class="mb-2">Favourites</span>
                    </button>
                </div>
            </div>
        </a>
    </h2>
    <!-- Modal -->
    <div class="modal fade" id="exampleModalfavouritesTwo" tabindex="-1" aria-labelledby="exampleModalfavouritesTwo" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content p-3" style="color: #457C9F;">
                <div class="modal-header border-0">
                    <div class="row w-100">
                        <div class="col-9">
                            <h5 class="modal-title" id="exampleModalLabelfavouritesTwo" style="color: #457C9F">Select Your Favourite</h5>
                        </div>
                        <div class="col-3 text-end pe-0">
                            <h5 style=" font-weight: 300;">Add new</h5>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <ul class="modal-fav ps-0 mb-0">
                                <li>
                                    <a>
                                        <img src="@/assets/images/icons/favorite-home.svg">
                                        <span>Home</span>
                                    </a>
                                </li>
                                <hr>
                            </ul>

                        </div>
                        <div class="col-12">
                            <ul class="modal-fav ps-0 mb-0">
                                <li>
                                    <a>
                                        <img src="@/assets/images/icons/favorite-work.svg">
                                        <span>Work</span>
                                    </a>
                                </li>
                                <hr>
                            </ul>

                        </div>
                        <div class="col-12">
                            <ul class="modal-fav ps-0 mb-0">
                                <li>
                                    <a>
                                        <img src="@/assets/images/icons/favorite-location.svg">
                                        <span>Nw10</span>
                                    </a>
                                </li>
                                <hr>
                            </ul>

                        </div>
                        <div class="col-12">
                            <ul class="modal-fav ps-0 mb-0">
                                <li>
                                    <a>
                                       <img src="@/assets/images/icons/favorite-location.svg">
                                       <span>Hw6</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-12">
                            <button type="button" class="btn fav-sub mt-3">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
            <div class="row">
                <div class="col-12">
                    <p style="color: #457C9F">To make sure the drivers gets to the exact place. please fill up any house or flat numbers appropriately.</p>
                </div>
                <div class="col-12">
                    <input type="text" class="form-control pickUp-inp" placeholder="Type your address">
                </div>
                <div class="col-6">
                    <input type="text" class="form-control pickUp-inp" placeholder="Flat">
                </div>
                <div class="col-6">
                    <input type="text" class="form-control pickUp-inp" placeholder="House Number/Name">
                </div>
                <div class="col-12">
                    <input type="text" class="form-control pickUp-inp" placeholder="Sender Name">
                </div>
                <div class="col-12">
                    <input type="tel" class="form-control pickUp-inp" placeholder="Phone Number">
                </div>
                <div class="col-6">
                    <button type="button" class="pickUp-btn">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "DropoffButton",
    props: ["dropoffbtnname"],
    data() {
        return {
            showFavoriteButton:false,
        }
    },
};
</script>
