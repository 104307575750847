import {createRouter, createWebHistory} from 'vue-router';
import { LocalStorageManager } from '@/services';
import errors from './errors';
import auth from './auth';
import common from './common';
import orders from './orders';
import otp from './otp';
import payments from './payments';
import favorites from './favorites';
import store from "@/store/index";

const routes = [
  ...common,

  ...auth,

  ...otp,

  ...orders,

  ...payments,

  ...favorites,

  ...errors,
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

function isLoggedin() {
  return !!LocalStorageManager.getToken();
}

router.beforeEach((to, from, next) => {
  store.dispatch("changeMenuState", false);
  if (!to.matched.length) {
    next({
      name: "NotFound"
    });
  }
  if (to.matched.some(rec => rec.meta.authOnly)) {
    if (!isLoggedin()) {
      next({
        name: "Login"
      });
    } else {
      next();
    }
  } else if (to.matched.some(rec => rec.meta.guestOnly)) {
    if (isLoggedin()) {
      next({
        name: 'NewOrder'
      })
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
