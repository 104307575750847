import {
  Payment,
  StripePage
} from '@/views/pages/payments';

export default [
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    meta: {
      authOnly: true,
      transition: 'fade'
    }
  },

  {
    path: "/stripe-page",
    name: "StripePage",
    component: StripePage,
    meta: {
      isPage: true,
      authOnly: true,
      transition: 'none'
    }, // need auth token and verify
  },
];
