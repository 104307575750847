<template>
  <h2>hello : {{ message }}</h2>
  <div class="padding-20">
    <div id="card-element"></div>
    <label>{{ error1 }}</label>
  </div>

  <button class="btn btn-primary" @click="getPaymentMethodToken">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loadingButton"></span>
    Click
  </button>

</template>

<script>


import {loadStripe} from '@stripe/stripe-js';

export default {
  name: "StripePage",
  data() {
    return {
      loadingButton : false,
      error1: 'error',
      message: "hello worddddddddddd"
    }
  },
  methods: {
    getPaymentMethodToken() {
      this.loadingButton=true;
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: 'john doe',
        },
      });
    },
  },

   async mounted() {
     this.stripe = await loadStripe('pk_test_8zmFpuSIc23I4hTswqmfwoef');
     var elements = this.stripe.elements();
     var style = {
       base: {
         lineHeight: '1.429',
         color: '#32325d',
         fontFamily: '"Nunito", Helvetica, sans-serif',
         fontSmoothing: 'antialiased',
         fontSize: '22px',
         '::placeholder': {
           color: '#eee'
         }
       },
       invalid: {
         color: '#fa755a',
         iconColor: '#fa755a'
       }
     };

     this.card = elements.create('card', {hidePostalCode: true, style: style});
     this.card.mount('#card-element');
   }
}
</script>
<style scoped>
.padding-20 {
  padding: 10px 50px;
}
</style>