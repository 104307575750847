import Form from '@/services/form';

import {
  required,
  minLength,
} from '@vuelidate/validators';


const data = {
  mobile: null,
  password: null,
  firebase_token: 'firebase token for web',
}

const rules = {
  mobile: {
    required,
    tel: null,
  },
  password: {
    required,
    minLength: minLength(6)
  },
}

export default () => new Form(data, rules, 'auth/login');
