<template>
<div class=" ps-5" >
    <router-link to="/login">Login</router-link><br>
    <router-link to="/404">404</router-link><br>
    <router-link to="/profile">profile</router-link><br>
    <router-link to="/current-order">current-order</router-link><br>
    <router-link to="/order-details">order-details</router-link><br>
    <router-link to="/signup">signup</router-link><br>
    <router-link to="/reset-password">reset-password</router-link><br>
    <router-link to="/forgot-password">forgot-password</router-link><br>
    <router-link to="/verification-code-signup">verification-code-signup</router-link><br>
    <router-link to="/verification-code-forgotpass">verification-code-forgotpass</router-link><br>
    <router-link to="/new-order">new-order</router-link><br>
    <router-link to="/favorites">favorites</router-link><br>
    <router-link to="/add-favorites">add-favorites</router-link><br>
    <router-link to="/payment">payment</router-link>
</div>
</template>

<script>
export default {
    name: 'Test',
}
</script>
