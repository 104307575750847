import Api from "./api.js";
import axios from "axios";

// axios.interceptors.request.use(config => {
//   // Set custom headers or modify the request as needed
//   config.headers['Authorization'] = 'Bearer YourAccessToken';
//   return config;
// });

export default {
  getList(page) {
    return Api().post(`v1/user/bookmark-list?page=${page}`);
  },

  add(payload) {
    return Api().post("v1/user/bookmark-add", payload);
  },

  update(payload) {
    return Api().post("v1/user/bookmark-update", payload);
  },

  remove(id) {
    return Api().post(`v1/user/bookmark-delete`, { id });
  },

  callBlueBoardApi(payload) {
    let { zone_no, street_no, building_no } = payload;

    let url = `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS_wgs84/MapServer/0/query?where=zone_no=${zone_no}+and+street_no=${street_no}+and+building_no=${building_no}&f=json`;
    let callApi = axios.create({
      baseURL: url,
    });

    return callApi.post("");
  },

  // callBlueBoardApi(payload) {
  //   let { zone_no, street_no, building_no } = payload;

  //   let url = `https://cors-anywhere.herokuapp.com/https://qarta.io/api/v1/addr/qna?zone=${zone_no}&street=${street_no}&building=${building_no}&key=dxnYEnJwI63jWiorDdrxkoshMrzv2j1EZpOKItfRW2g`;

  //   return axios.get(url);
  // },
};
