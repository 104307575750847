<template>
  <div class="container-fluid">
    <div class="row  set-max-height">

      <!-- ****************** left side bar ********************* -->
      <div class="col-md-6 order-md-first ">
        <div class="row ">
          <div class="col-md-8 offset-md-2 col-10 offset-1">

            <div class="header-form header-form-peyk">
              <img src="@/assets/images/icons/logo.png" class="mt-5" width="100">

              <h1 class="mt-5 pt-5">Set Password</h1>
              <p class="my-3">Send anything to anywhere in minutes</p>
            </div>

            <div class="body-form mb-5 pb-5 mt-4">
              <Input
                class="col-12 mt-4"
                v-focus
                :form="form"
                :vuelidate="v$"
                field="password"
                name="Your New Password"
                type="password"
              />

              <Input
                class="col-12 mt-4"
                :form="form"
                :vuelidate="v$"
                field="password_confirmation"
                name="Password Confirmation"
                type="password"
              />

              <SubmitButton class="d-grid gap-2 mt-5"
                :form="form"
                :vuelidate="v$"
                :canSubmit="true"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- ****************** right side bar ********************* -->
      <div class="col-md-6 px-0 order-md-first order-first d-none d-sm-none d-md-block d-lg-block right-side">
        <figure class="setpassword-page border-position-pics"></figure>
      </div>

    </div>
  </div>
</template>

<script>
import { ResetPassword } from '@/forms/forgot-password';
import {
  Input,
  SubmitButton,
} from '@/components/forms';

export default {
  name: 'SetPassword',

  components: {
    Input,
    SubmitButton,
  },

  setup() {
    const form = new ResetPassword();
    const { v$ } = form;

    return { form, v$ };
  }
}
</script>
