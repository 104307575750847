<template>
  <div class="form-floating form-floating-peyk dropdown">
    <input
      class="form-control form-control-peyk"
      :value="value"
      :placeholder="placeholder$"
      :type="type"
      ref="input"
    >
    <label for="label">{{ caption$ }}</label>

    <ul class="dropdown-list">
      <li v-for="(item, index) in list" :key="index" @click="updateValue(item)">
        {{ item }}
      </li>
    </ul>

    <InputError
      :form="form"
      :fieldVuelidate="v$"
      :errors="e$"
      :field="field"
      :name="name$"
    />
  </div>
</template>

<script>
import InputError from './InputError.vue';
import { inputProps, inputComputed } from './InputAttributes';

export default {
  name: 'SearchAddress',

  data() {
    return {
      list: [],
    }
  },

  components: {
    InputError,
  },

  props: inputProps,

  computed: inputComputed,

  mounted() {
    const intervalHandle = setInterval(() => {
      if (! window.google || ! window.google.maps.places) {
        return;
      }

      this.initAutocomplete();
      clearInterval(intervalHandle);
    }, 500);
  },

  methods: {
    initAutocomplete() {
      const options = {
        fields: ['formatted_address', 'geometry', 'name'],
        strictBounds: false,
        types: ['establishment', 'geocode'],
      };

      this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.input, options);
      this.autocomplete.setComponentRestrictions({
        country: ["qa"],
      });
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        this.updateValue(place);
      });
    },

    updateValue(place) {
      this.form.set(this.field, place.formatted_address);
      this.$emit('locationChanged', {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });

      if (this.v$) {
        this.v$.$touch();

        this.e$.clear(this.field);
      }
    },
  }

}
</script>

<style scoped>

.dropdown {
  position: relative;
}

.dropdown-list {
  position: absolute;
  width: calc(100% - 25px);
  background-color: white;
  padding-left: 10px;
  z-index: 1;
}

.dropdown-list li {
  list-style: none;
  margin: 5px 0;
  cursor: pointer;
  color: var(--color-link);
}
</style>
