export default class LocalStorageManager {
  static get(key) {
    return localStorage.getItem(key);
  }

  static set(key, value) {
    localStorage.setItem(key, value);
  }

  static remove(key) {
    localStorage.removeItem(key);
  }

  static getJson(key) {
    const data = this.get(key);

    return data ? JSON.parse(data) : {};
  }

  static clearVuexStore() {
    this.remove('peyk_space_information');
  }

  static getFromVuexStore(...keys) {
    const data = this.getJson('peyk_space_information');

    return keys.reduce((acc, key) => acc ? acc[key] : null, data);
  }

  static getToken() {
    return this.getFromVuexStore('auth', 'token');
  }
}
