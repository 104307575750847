<template>
  <span class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
    v-if="isLoading"
  ></span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Loading',

  computed: {
    ...mapState(['isLoading']),
  },
}
</script>
