<template>
  <h1>426 : Force Update is required! </h1>
</template>

<script>
export default {
  name: "ForceUpdate"
}
</script>

<style scoped>

</style>
