<template>
    <Collapse v-model="bodyVisible"  :class="{disabled: !vehicles[0]}">
    <!-- <div class="schedule-content"> -->
            <template v-slot:header>
                <div class="schedule-header">
                    <img src="@/assets/images/icons/date-time.jpeg" class="wallet-icon" alt="wallet">
                    <span class="schedule-text">Schedule <span v-if="scheduleValue">:-</span></span> 
                    <span v-if="scheduleValue" class="schedule-time">{{ formattedSelectedTime }}</span>
                </div>
            </template>
            <template v-slot:default>
                <div class="schedule-form">
                    <div class="schedule-buttons">
                        <button class="btn btn-immediate" @click="setImmediate">Immediate</button>
                        <button class="btn btn-add-schedule" @click="openDateTimePicker">Add Schedule</button>
                    </div>

                    <div v-if="showDateTimePicker" class="schedule-datetime">
                        <input type="datetime-local" v-model="selectedDateTime" :min="minDateTime" />
                        <button class="btn" :disabled="!selectedDateTime" @click="onDateTimeSelected">Select</button>
                    </div>
                </div>
            </template>
        <!-- </div> -->
    </Collapse>
</template>
  
<script>
import { mapState } from 'vuex';
import Collapse from '../Collapse.vue';
import moment from 'moment';
import 'moment-timezone';
import {toaster} from '@/common/helpers';

export default {
    name: "ScheduleComponent",
    components: {
        Collapse,
    },
    computed: {
        ...mapState('orders', ['vehicles']),
        formattedSelectedTime() {
            if (this.scheduleValue === 'immediate') {
                return 'Immediate';
            } else if (this.scheduleValue) {
                const scheduleDate = new Date(this.scheduleValue);
                const options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                };
                return scheduleDate.toLocaleString('en-US', options);
            }
            return 'immediate';
        },
    },
    data() {
        return {
            bodyVisible: false,
            showDateTimePicker: false,
            selectedDateTime: '',
            scheduleValue: 'immediate',
            minDateTime: ''
        };
    },
    methods: {
        setImmediate() {
            this.showDateTimePicker = false;
            this.selectedDateTime = ''
            this.scheduleValue = 'immediate'
            this.$emit('ScheduleSelected', this.scheduleValue);
        },
        openDateTimePicker() {
            this.showDateTimePicker = true;
            const qatarTimezone = 'Asia/Qatar';
            const currentDateInQatarTimezone = moment.tz(qatarTimezone).format('YYYY-MM-DDTHH:mm');
            this.selectedDateTime = currentDateInQatarTimezone;
            this.minDateTime = currentDateInQatarTimezone;
            this.showDateTimePicker = true;
        },
        onDateTimeSelected() {
            const selectedMoment = moment(this.selectedDateTime);
            const currentMoment = moment().add(30, 'minutes');

            if (selectedMoment.isBefore(currentMoment)) {
                toaster().error("Choose a time that is 30 minutes or more from the current time.");
            } else {
                this.scheduleValue = selectedMoment.format();
                this.showDateTimePicker = false;
                this.$emit('ScheduleSelected', this.scheduleValue);
            }
        },
    },
};
</script>
  
<style scoped>

.disabled{
    pointer-events: none;
    opacity: 0.3;
}
.schedule-content {
    margin-top: 20px;
}

.schedule-text {
    color: #00204A;
}

.schedule-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.schedule-header>.schedule-icon {
    width: 20px;
}

.btn {
    border: 1px solid #D7D8D9;
    border-radius: 5px;
    padding: 7px 9px;
    color: #457C9F !important;
}

.schedule-buttons {
    display: flex;
    justify-content: space-around;
    margin: 10px;
}
.schedule-form{
    margin: 20px 0;
}
.schedule-datetime{
    display: flex;
    justify-content: center;
    align-items: center;
}
.schedule-datetime input{
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem 0 0 0.375rem;
}
.schedule-datetime .btn{
    border-radius: 0 0.375rem 0.375rem 0 !important;
    padding: 6px 9px !important;
    cursor: pointer;
}
.schedule-datetime .btn:hover {
    background-color: unset;
    border-color: unset;
}
</style>
  