<template>
  <div class="peyk_container">
    <PageTitle title="Order Tracking"/>

    <div class="row m-0">
      <div class="col-3 px-4 overflow-auto" style="height: 87vh">
        <div class="card">
          <section class="card-header">
            <span class="order-number">#{{ orderStatueData?.id }}Pass</span>
            <span class="order-price">QAR{{ orderStatueData?.user_price }}</span>
          </section>
          <section class="card-body">
            <div class="pickup-section">
              <span class="pickup-title">Pickup Address</span>
              <div class="address-section">
                <span class="new-circle"></span>
                <span>{{orderStatueData?.sender_address}}</span>
              </div>
            </div>
            <div class="dropoff-section">
              <span class="dropoff-title">Drop off Address</span>
              <div class="address-section" v-for="(dropoff, index) in orderStatueData?.receiver_info" :key="dropoff.address">
                <span class="new-square">{{index + 1}}</span>
                <span>{{dropoff?.address}}</span>
              </div>
            </div>
            <div class="driver-section">
              <div class="driver-header">
                <img class="driver-image" :src="driverAvatar" alt="">
                <div class="driver-info">
                  <span class="driver-name">
                    {{ orderStatueData?.driver_information?.first_name }}&nbsp;{{ orderStatueData?.driver_information?.lastName }}
                  </span>
                  <span class="driver-type">Driver</span>
                </div>
                <div class="score">
                  <img src="@/assets/images/icons/Icon-awesome-star-color.svg" alt="">
                  <span class="score-text">{{orderStatueData?.driver_information?.score ? (Math.round(orderStatueData?.driver_information?.score * 100) / 100).toFixed(1) : "0.0"}}</span>
                </div>
              </div>
              <div class="driver-information">
                <span class="information-title">Phone Number</span>
                <span class="information-value">{{orderStatueData?.driver_information?.mobile}}</span>
              </div>
              <div class="driver-information">
                <span class="information-title">Sender Name</span>
                <span class="information-value">{{orderStatueData?.sender_name}}</span>
              </div>
              <div class="driver-information">
                <span class="information-title">Return</span>
                <span class="information-value">{{orderStatueData?.driver_information?.returnTrip  ? 'YSE' : 'No'}}</span>
              </div>
              <div class="driver-information">
                <span class="information-title">Distance to Pickup point</span>
                <span class="information-value">{{orderStatueData?.ETA?.distance}}</span>
              </div>
            </div>
            <div class="text-driver">
              <img src="@/assets/images/icons/bold-messages.svg" alt="">
              <span class="text-driver-text">Text to Message</span>
            </div>
            <div class="two-column">
              <div class="support-section" @click.prevent="supportModalVisible = true;">
                <img src="@/assets/images/icons/bold-headphone.svg" alt="">
                <span>Support</span>
                <img class="last" src="@/assets/images/icons/bold-call.svg" alt="">
              </div>
              <div class="share-section" @click.prevent="shareModalVisible = true;">
                <img src="@/assets/images/icons/bold-share.svg" alt="">
                <span>Share Link</span>
                <img class="last" src="@/assets/images/icons/bold-document-copy.svg" alt="">
              </div>
            </div>
            <CancelOrder :step="orderStatueData?.step" @cancelOrderAction="orderCancelWithReason"/>
          </section>
        </div>

      </div>

      <div class="col-9 position-relative">

        <StepIndicator
            :max-step="orderStatueData?.final_step"
            :current-step="orderStatueData?.step"
            :order-data="orderStatueData"
        />

        <GoogleMapLive
            :geolocationEnabled="false"
            :route-path="routePath"
            :markers="markers"
            :path="path"
            :bounds="bounds"
        />

      </div>
    </div>

    <Fade>
      <div class="custom-modal-background" @click.self="supportModalVisible = false;" v-if="supportModalVisible">
        <div class="custom-modal">
          <div class="modal-header">
            <span>Support</span>
          </div>
          <div class="modal-body">
            <p>
              This toggle is used to request a callback to the Pass operator.
              In case of any emergencies or urgent concerns please use this feature and the operator will contact you shortly.
            </p>
          </div>
          <div class="modal-footer">
            <button @click="report_call(orderStatueData?.id)" class="secondary-color">Confirm</button>
          </div>
        </div>
      </div>
    </Fade>

    <Fade>
      <div class="custom-modal-background" @click.self="shareModalVisible = false;" v-if="shareModalVisible">
        <div class="custom-modal">
          <div class="modal-header">
            <span>Share</span>
          </div>
          <div class="modal-body">
            <input id="url" type="text" :value="orderStatueData?.safe_url">
          </div>
          <div class="modal-footer">
            <button @click="copyToClipboard" class="secondary-color">Copy</button>
          </div>
        </div>
      </div>
    </Fade>
  </div>
</template>

<script>
import {Fade} from "@/components/transitions";
import {GoogleMapLive} from '@/components';
import PageTitle from "@/components/PageTitle";
import {
  StepIndicator,
  CancelOrder,
} from "@/components/orders";
import {orders} from '@/api';
import {toaster} from '@/common/helpers';
import router from '@/router';
import {loadStripe} from "@stripe/stripe-js";
import { peyk } from '@/configs';
import {mapMutations} from "vuex";

export default {
  name: 'OrderTracking',

  props: ['id'],

  components: {
    PageTitle,
    StepIndicator,
    GoogleMapLive,
    CancelOrder,
    Fade,
  },

  data() {
    return {
      markers: [],
      path: [],
      orderStatueData: null,
      orderStatusInterVal: null,
      clientSecret: null,
      PaymentIntentID: null,

      orderScoreForm: {
        order_id: null,
        score: null,
      },
      supportModalVisible: false,
      shareModalVisible: false,
    };
  },

  computed: {
    routePath() {
      let dataPosition = this.orderStatueData?.ETA?.route;
      if (!dataPosition) {
        return [];
      }
      let arrayData = dataPosition.split('|');
      return arrayData.map((stringPosition) => this.getPositionString(stringPosition));
    },

    bounds() {
      return this.markers.map((marker) => marker.position);
    },
    driverAvatar() {
      return this.orderStatueData?.driver_information?.avatar != "" ? this.orderStatueData?.driver_information?.avatar : require('@/assets/images/icons/driver-avatar.png')
    },
  },

  async mounted() {
    let orderID = this.$route.params.id;
    toaster().success("Please Wait ...");
    await this.callStatus(orderID);
    this.setMarkers();
  },

  methods: {
    ...mapMutations('orders', ['setLastOrderId' , 'removeOrder']),
    getPosition(location) {
      if (location){
        const {lat, long} = location;
        return {lat: parseFloat(lat), lng: parseFloat(long)};
      }
      return {lat: null, lng: null};
    },
    getPickupIcon() {
      return {
        type: 'pickup',
        position: this.getPositionString(this.orderStatueData?.sender_location),
        url: require("@/assets/images/icons/pickup.png"),
        index: 0
      };
    },

    getDropoffIcon_1() {
      return {
        type: 'dropoff_1',
        position: this.getPosition(this.orderStatueData?.receiver_info[0]),
        // position: this.getPosition(this.destination),
        url: require("@/assets/images/icons/dropoff-1.png"),
      };
    },
    getDropoffIcon_2() {
      return {
        type: 'dropoff_2',
        position: this.getPosition(this.orderStatueData?.receiver_info[1]),
        // position: this.getPosition(this.destination),
        url: require("@/assets/images/icons/dropoff-2.png"),
      };
    },
    getDropoffIcon_3() {
      return {
        type: 'dropoff_3',
        position: this.getPosition(this.orderStatueData?.receiver_info[2]),
        // position: this.getPosition(this.destination),
        url: require("@/assets/images/icons/dropoff-3.png"),
      };
    },
    copyToClipboard(){
      // Get the text field
      const copyText = document.getElementById("url");

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);

      // Alert the copied text
      toaster().success("Link copied to your clipboard");

      this.shareModalVisible = false;
    },

    async callStatus(orderID) {
      await this.getOrderStatus(orderID);
      this.orderStatusInterVal = setInterval(() => {
        this.getOrderStatus(orderID);
      }, 5000);
    },

    setMarkers() {
      let dataMarker =  [this.getPickupIcon()]

      this.orderStatueData?.receiver_info?.map((i,index)=>{
        dataMarker.push({
          type: `dropoff`,
          position: this.getPosition(this.orderStatueData?.receiver_info[index]),
          url: require(`@/assets/images/icons/dropoffs.png`),
          index: index+1
        })
      })

      this.markers = dataMarker.filter(item => item.position?.lat != null);

      let dropOffPath= [];
      dropOffPath.push(this.getPosition({lat: this.orderStatueData?.sender_lat, long: this.orderStatueData?.sender_long}))
      for (let i = 0; i < this.orderStatueData?.receiver_info?.length; i++) {
          dropOffPath.push(this.getPosition(this.orderStatueData?.receiver_info[i]))
      }
      console.log("dropOffPath",dropOffPath);
      this.path = dropOffPath.filter(item => item.lat != null);
    },

    async orderCancelWithReason(payload) {
      let dataPayload = payload;
      dataPayload.order_id = this.orderStatueData?.id;
      let result = await orders.orderCancelWithReason(payload);
      if (result) {
        this.removeOrder(dataPayload.order_id);
        this.setLastOrderId(0);
        clearInterval(this.orderStatusInterVal);
        toaster().success("Cancel order successfully", {duration: 6000});
        router.push({
          name: 'NewOrder',
        });
      }
    },

    async submitAjax()
    {
      try {
        const { data } = await orders.driverScore(this.orderScoreForm);
        return data.data?.status === 'success';
      } catch (error) {

        if (error['http-code'] === 402) {
          this.clientSecret = error?.data?.clientSecret;
        }
        return false
      }
    },

    async driverScoreAction(payload) {

      this.orderScoreForm.order_id  = this.orderStatueData?.id;
      this.orderScoreForm.score     = payload.score;

      await this.submitAjax();

      router.push({
        name: 'NewOrder',
      });
      //redirect to new order
      toaster().success("Score complete ...")

    },

    async getStripeResult() {

      try {
        this.stripe = await loadStripe(peyk.stripe_key); // todo: load key from settings

        const result = await this.stripe.handleCardAction(this.clientSecret);

        if (result.data) {
          return;
        }

        this.orderScoreForm.payment_intent_id = result.paymentIntent.id;  //this id add form
        await this.submitAjax();
      } catch (error) {
        toaster().error(error.message);
      }
    },

    async getOrderStatus(order_id) {
      let result = await orders.orderStatus({order_id});
      this.orderStatueData = result.data.data;


      if (this.orderStatueData?.step ==-1){
        toaster().warning("Cancel order", {duration: 6000});
        router.push({
          name: 'NewOrder',
        });
      }

      if (this.orderStatueData?.is_completed){
        toaster().success("Order completed successfully", {duration: 6000});
        router.push({
          name: 'NewOrder',
        });
      }

      if (this.orderStatueData?.last_location) {
        const driverMarker = {
          position: this.getPositionString(this.orderStatueData?.last_location),
          url: require('@/assets/images/icons/driver.png'),
          type: "driver",
        };

        let markers = this.markers;
        let index = markers.findIndex(item => {return item.type && item.type == "driver"});
        if (index >= 0){
          markers[index] = driverMarker;
        }else{
          markers.push(driverMarker);
        }
        this.markers = [...markers];
      }
    },

    async report_call(order_id) {
      let result = await orders.report({
        order_id: order_id,
        status_type: 'IMPORTANT',
        status_label: 'operator-call'
      });

      if (result) {
        toaster().success('Operator call ', {duration: 5000});
      }
      this.supportModalVisible = false;
    },

    getPositionString(locationString) {
      const location = locationString.split(',');
      return {
        lat: parseFloat(location[0]),
        lng: parseFloat(location[1]),
      };
    },

    getPositionObject(locationObject) {
      if (locationObject){
        const {lat, long} = locationObject;
        return {
          lat: parseFloat(lat),
          lng: parseFloat(long),
        };
      }
      return {lat: null, lng: null};
    },

  },
  unmounted() {
    clearInterval(this.orderStatusInterVal);
  }


}
</script>
<style scoped>
.custom-modal-background{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1055;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}
.custom-modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 26px 33px;
  gap: 24px;
  border: 1px solid #75757521;
  border-radius: 22px 22px 0 0;
  font-family: Nunito, sans-serif;
}
.custom-modal > .modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-modal > .modal-header > span{
  font-family: Nunito, sans-serif;
  color: #00204A;
  font-weight: bold;
  font-size: 24px;
}
.custom-modal > .modal-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: #00204A;
}
.custom-modal > .modal-body > p{
  font-family: Nunito, sans-serif;
  font-weight: normal;
  font-size: 16px;
}
.custom-modal > .modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.custom-modal > .modal-footer > button{
  flex-grow: 1;
  background: #00204A;
  border-radius: 5px;
  padding: 14px 0;
  color: #fff;
  font-size: 20px;
  border: none;
  outline: none;
}
.custom-modal > .modal-footer > button.secondary-color{
  background: #00B7C2;
}
</style>
<style scoped>
#url{
  border: 1px solid #ECECEC;
  border-radius: 10px;
  padding: 14px 8px;
  font-family: Nunito, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #00204A;
}
.new-square{
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Bariol, sans-serif;
  font-weight: bold;
  font-size: 16px;
}
.card{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  color: #00204A;
  font-family: Nunito, sans-serif;
}
.card-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  padding: 16px 14px;
  border: none;
  border-bottom: 1px solid #ECECEC;
}
.order-number{
  font-family: Bariol, sans-serif;
  font-weight: normal;
  font-size: 20px;
}
.order-price{
  font-weight: bold;
  font-size: 28px;
}
.card-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px 14px;
  gap: 14px;
}
.pickup-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 11px;
}
.pickup-title, .dropoff-title{
  font-weight: normal;
  font-size: 16px;
}
.dropoff-title{
  margin-bottom: 12px;
}
.address-section{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  padding: 12px 10px;
  background: #FBF4EE;
  border: 1px solid #ECECEC;
  border-radius: 10px;
}
.dropoff-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 3px;
}
.driver-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background: #FBF4EE;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  gap: unset;
}
.driver-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  padding: 15px 13px 10px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.09);
}
.driver-image{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 6px #00000029;
  border: 1px solid #70707036;
}
.driver-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 2px;
}
.driver-name{
  font-weight: normal;
  font-size: 24px;
}
.driver-type{
  font-weight: normal;
  font-size: 16px;
  color: #9F9F9F;
}
.score{
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.score > img{
  width: 22px;
  height: 22px;
}
.score-text{
  font-family: Bariol, sans-serif;
  font-weight: bold;
  font-size: 14px;
}
.driver-information{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 13px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.09);
}
.driver-information:last-child{
  border-bottom: none;
}
.text-driver{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  padding: 18px 12px;
  background: #FBF4EE;
  border: 1px solid #ECECEC;
  border-radius: 10px;
}
.text-driver > img{
  width: 24px;
  height: 24px;
}
.text-driver-text{
  font-weight: normal;
  font-size: 16px;
}
.two-column{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
}
.support-section, .share-section{
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  padding: 18px 12px;
  background: #FBF4EE;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  cursor: pointer;
}
.support-section > img, .share-section > img{
  width: 24px;
  height: 24px;
}
.support-section > span, .share-section > span{
  font-weight: normal;
  font-size: 16px;
}
.support-section > .last, .share-section > .last{
  margin-left: auto;
}
.information-title{
  font-weight: normal;
  font-size: 14px;
  color: #BCBCBC;
}
.information-value{
  font-weight: bold;
  font-size: 14px;
}
.order-status {
  background: #ffffff9c;
  position: absolute;
  z-index: 9;
  width: 99%;
}

.peyk_container {
  padding-left: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: white;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #fbfbfb94;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:focus {
  background: rgb(88, 88, 88);
}
</style>