import Form from '@/services/form';

import {
  required,
  numeric,
  minLength,
} from '@vuelidate/validators';


const data = {
  verification_code: null,
}

const rules = {
  verification_code: {
    required,
    numeric,
    minLength: minLength(6),
  },
}

export default () => new Form(data, rules, 'auth/verifyForgotPasswordMobile');
