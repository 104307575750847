import { computed } from 'vue';
import { useVuelidate } from "@vuelidate/core";
import * as validators from '@vuelidate/validators';
import Errors from "./errors";

export default class Validator {
  /**
   * Computed refs used for dynamic validators.
   * @var {Array}
   */
  refs = [];

  /**
   * Create a new Validator instance.
   *
   * @param {Object} rules
   * @param {Object} data
   */
  constructor(rules, data) {
    this.processDynamicValidators(rules, data);
    this.processTel(rules);

    this.v$ = useVuelidate(
      computed(() => rules),
      data,
        {
          $autoDirty: true,
        }
    );

    this.errors = new Errors();
  }

  /**
   * Process the dynamic rules.
   *
   * @param  {Object} rules
   * @param  {Object} data
   */
  processDynamicValidators(rules, data) {
    for (let rule of Object.values(rules)) {
      const { dynamicValidators } = rule;
      if (! dynamicValidators) {
        continue;
      }

      dynamicValidators.forEach(({ type, field, params }) => {
        const ref = computed(() => data[field]);
        this.refs.push(ref);

        rule[type] = validators[type](ref, ...params);
      });

      delete rule['dynamicValidators'];
    }
  }

  /**
   * Process the dynamic rules.
   *
   * @param  {Object} rules
   */
  processTel(rules) {
    for (let rule of Object.values(rules)) {
      if (rule.tel !== undefined) {
        rule.tel = () => !!this.tel?.valid;
      }
    }
  }

}
