<template>
  <div class="text-danger small mt-1" v-if="showNoError || hasError">
    <template v-if="hasError">
      <template v-if="hasServerError">
        <div class="ps-1">{{ e$.get(field) }}</div>
      </template>

      <template v-else>
        <div class="ps-1" v-if="clientErrorType === 'required'">{{ name }} is required</div>
        <div class="ps-1" v-if="clientErrorType === 'minLength'">{{ name }} should not be less than {{ v$.minLength.$params.min }} charecters</div>
        <div class="ps-1" v-if="clientErrorType === 'maxLength'">{{ name }} should not be more than {{ v$.maxLength.$params.max }} charecters</div>
        <div class="ps-1" v-if="clientErrorType === 'numeric'">{{ name }} should be number</div>
        <div class="ps-1" v-if="clientErrorType === 'email'">{{ name }} is invalid</div>
        <div class="ps-1" v-if="clientErrorType === 'sameAs'">{{ name }} must be equal to the {{ v$.sameAs.$params.otherName }}</div>
        <div class="ps-1" v-if="clientErrorType === 'tel'">{{ name }} is invalid.</div>
      </template>
    </template>

    <template v-else>&nbsp;</template>
  </div>
</template>

<script>
export default {
  name: 'InputError',

  props: {
    form: Object,
    fieldVuelidate: Object, //Field Vuelidate Object
    errors: Object, //Form Error Object
    field: String,
    name: String,
    showNoError: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      hasTelError: false,
    }
  },

  computed: {
    v$() {
      return this.fieldVuelidate;
    },

    e$() {
      return this.errors;
    },

    hasServerError() {
      return this.e$.has(this.field);
    },

    hasClientError() {
      return this.v$?.$errors?.length || this.hasTelError;
    },

    hasError() {
      return this.hasServerError || this.hasClientError;
    },

    clientErrorType() {
      return this.hasClientError ? this.v$.$errors[0].$validator : null;
    },
  },
}
</script>

<style>

</style>
