import {
  Favorites,
  AddFavorite, UpdateFavorite,
} from '@/views/pages/favorites';

export default [
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites,
    meta: {
      authOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/add-favorite',
    name: 'AddFavorite',
    component: AddFavorite,
    meta: {
      authOnly: true,
      transition: 'fade'
    }
  },

  {
    path: '/update-favorite/:id',
    name: 'UpdateFavorite',
    component: UpdateFavorite,
    meta: {
      authOnly: true,
      transition: 'fade'
    }
  },
];
