<template>
  <input :value="value" :placeholder="placeholder$" :type="type" @input="updateValue" :id="field" v-bind="$attrs">
  <InputError
      :form="form"
      :fieldVuelidate="v$"
      :errors="e$"
      :field="field"
      :name="name$"
      :show-no-error="false"
  />
</template>

<script>
import InputError from './InputError.vue';
import { inputProps, inputComputed } from './InputAttributes';

export default {
  name: "PasswordInput",

  components: {
    InputError,
  },

  props: inputProps,

  computed: inputComputed,

  methods: {
    updateValue(e) {
      this.form.set(this.field, e.target.value);

      if (this.v$) {
        this.v$.$touch();

        this.e$.clear(this.field);
      }
    },
  }
}
</script>

<style scoped>
input{
  padding-bottom: 8px;
  outline: none;
  border: none;
  border-bottom: 1px solid #ECECEC;
  font-size: 18px;
}
input::placeholder{
  color: #00204A;
}
</style>