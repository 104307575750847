<template>
  <i class="bi text-info " style="font-size: 2rem" :class="[icons[favorite.type]]"></i>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FavoriteIcon',

  props: {
    favorite: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('favorites', ['icons']),
  },
}
</script>
