<template>
  <div>
    <Collapse v-model="bodyVisible">
      <template v-slot:header>
        <div class="row w-100">
          <div class="col-md-8 col-lg-9 text-start pt-1 pe-0">
            <img
              src="@/assets/images/icons/circle.png"
              class="me-md-2 me-lg-3"
            />
            <span>Where is your pickup?</span>
            <span class="d-none">NW10</span>
          </div>
          <div class="col-md-4 col-lg-3 px-0">
            <button type="button"
              class="btn btn-fav"
              v-if="bodyVisible"
              @click="showFavoritesModal"
            >
              <img
                src="@/assets/images/icons/heart.svg"
                alt="hart-favpurites"
                class=""
              />
              <span class="mb-2">Favourites</span>
            </button>
          </div>
        </div>
      </template>

      <template v-slot:default>
        <form class="row" @submit.prevent="submit">
          <div class="col-12">
            <p style="color: #457c9f">
              To make sure the drivers gets to the exact place. please fill up
              any house or flat numbers appropriately.
            </p>
          </div>

          <Input
            class="col-12"
            v-focus
            :form="form"
            :vuelidate="v$"
            field="name"
            name="Sender Name"
          />

          <Input
            class="col-12"
            :form="form"
            :vuelidate="v$"
            field="address"
          />

          <TelInput
            class="col-12"
            :form="form"
            :vuelidate="v$"
            field="phone"
          />

          <Input
            class="col-6"
            :form="form"
            :vuelidate="v$"
            field="street_name"
            name="Street"
          />

          <Input
            class="col-6"
            :form="form"
            :vuelidate="v$"
            field="postcode"
            name="Zip Code"
          />

          <SubmitButton class="col-12"
            :form="form"
            :vuelidate="v$"
          >Confirm</SubmitButton>
        </form>
      </template>
    </Collapse>

    <Modal v-model="favoritesModalVisible">
      <template v-slot:header>
        Select Your Favourite
      </template>

      <template v-slot:default>
        <div class="row">
          <div class="col-12">
            <ul class="modal-fav ps-0 mb-0">
              <li>
                <a>
                  <img src="@/assets/images/icons/favorite-home.svg" />
                  <span>Home</span>
                </a>
              </li>
              <hr />
            </ul>
          </div>
          <div class="col-12">
            <ul class="modal-fav ps-0 mb-0">
              <li>
                <a>
                  <img src="@/assets/images/icons/favorite-work.svg" />
                  <span>Work</span>
                </a>
              </li>
              <hr />
            </ul>
          </div>
          <div class="col-12">
            <ul class="modal-fav ps-0 mb-0">
              <li>
                <a>
                  <img src="@/assets/images/icons/favorite-location.svg" />
                  <span>Nw10</span>
                </a>
              </li>
              <hr />
            </ul>
          </div>
          <div class="col-12">
            <ul class="modal-fav ps-0 mb-0">
              <li>
                <a>
                  <img src="@/assets/images/icons/favorite-location.svg" />
                  <span>Hw6</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="row w-100">
          <div class="col-6">
            <button type="button" class="btn btn-primary btn-primary-peyk w-100">Submit</button>
          </div>

          <div class="col-6">
            <button type="button" class="btn btn-secondary btn-secondary-peyk w-100">Add New</button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Collapse from '../Collapse.vue';
import Modal from '../Modal.vue';
import { PickupForm } from '@/forms/orders';
import {
  Input,
  TelInput,
  SubmitButton,
} from '@/components/forms';

export default {
  name: "Pickup",

  components: {
    Collapse,
    Modal,
    Input,
    TelInput,
    SubmitButton,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      bodyVisible: true,
      favoritesModalVisible: false,
    };
  },

  setup() {
    const form = PickupForm();
    const { v$ } = form;

    return {
      form,
      v$,
    }
  },

  mounted() {
    this.form.assign(this.modelValue);
  },

  methods: {
    showFavoritesModal() {
      this.favoritesModalVisible = true;
      setTimeout(() => this.bodyVisible = true, 0);
    },

    submit() {
      this.$emit('update:modelValue', this.form.data);

      this.bodyVisible = false;
    },
  },

  watch: {
    modelValue() {
      this.form.assign(this.modelValue, true);
    },
  }
}
</script>

<style>

.house-number label {
  font-size: 12px;
}

</style>
