import { business } from '@/api';
import { showMessage, isSuccessful } from '@/common/helpers';

const state = () => ({
    tokens: [],
    paginateInfo: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0
    },
});

const mutations = {
    setTokens(state, payload) {
        state.tokens = payload;
    },

    setPaginate(state, payload) {
        state.paginateInfo = payload;
    },

    addToTokens(state, payload){
        const {tokens} = state;
        state.tokens = [...tokens, ...payload]
    },

    addTokens(state, payload) {
        const { tokens } = state;

        tokens.unshift(payload);

        state.tokens = [...tokens];
    },

    updateTokens(state, payload) {
        const { tokens } = state;

        let index = tokens.findIndex(item => item.id === payload.id);

        tokens[index] = payload;

        state.tokens = [...tokens];
    },

    removeTokens(state, payload) {
        state.tokens = [...state.tokens.filter(token => token.id !== payload)];
    },
}

const actions = {
    async getTokens({ commit }, payload) {
        if (!payload){
            payload = 1;
        }
        commit('setIsLoading', true, { root: true });
        const { data } = await business.tokenList(payload);
        commit('setIsLoading', false, { root: true });
        commit('setTokens', Array.isArray(data.data) ? data.data : []);
        commit('setPaginate', {
            current_page: data.data.current_page,
            last_page: data.data.last_page,
            per_page: data.data.per_page,
            total: data.data.total
        })
    },
    async addGetToken({ commit }, payload) {
        if (!payload){
            payload = 1;
        }
        commit('setIsLoading', true, { root: true });
        const { data } = await business.tokenList(payload);
        commit('setIsLoading', false, { root: true });
        commit('addToTokens', data.data.result);
        commit('setPaginate', {
            current_page: data.data.current_page,
            last_page: data.data.last_page,
            per_page: data.data.per_page,
            total: data.data.total
        })
    },

    async addToken({ commit }, payload) {
        const { data } = await business.addToken(payload);

        commit('addTokens', data.data);

        showMessage(data);

        return data;
    },

    async updateToken({ commit }, payload) {
        const { data } = await business.update(payload);

        commit('updateTokens', data.data);

        showMessage(data);

        return isSuccessful(data);
    },

    async removeToken({ commit }, payload) {
        const { data } = await business.removeToken(payload);

        commit('removeTokens', payload);

        showMessage(data);
    },
}

const getters = {
    //
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
