<template>
  <div class="custom-input-group">
      <label :for="field">{{placeholder$}}</label>
      <input :value="value" :placeholder="placeholder$" :type="type" @input="updateValue" :id="field" v-bind="$attrs">
      <span class="icon">
        <slot name="icon">
          <i class="bi bi-pencil-fill"></i>
        </slot>
      </span>
  </div>
  <InputError
      :form="form"
      :fieldVuelidate="v$"
      :errors="e$"
      :field="field"
      :name="name$"
      :show-no-error="false"
  />
</template>

<script>
import InputError from './InputError.vue';
import { inputProps, inputComputed } from './InputAttributes';

export default {
  name: "CustomInput",

  components: {
    InputError,
  },

  props: inputProps,

  computed: inputComputed,

  methods: {
    updateValue(e) {
      this.form.set(this.field, e.target.value);

      if (this.v$) {
        this.v$.$touch();

        this.e$.clear(this.field);
      }
    },
  }
}
</script>

<style scoped>
.custom-input-group{
  position: relative;
  width: 100%;
}
.custom-input-group > label{
  position: absolute;
  top: 0;
  background: #fff;
  color: #B7B7B7;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  left: 20px;
  transform: translate(0, -50%);
  padding-left: 5px;
  padding-right: 10px;
}
.custom-input-group > input{
  width: 100%;
  outline: none;
  border: 2px solid #EAEAEA;
  border-radius: 5px;
  padding: 20px;
  color: #00204A;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}
.custom-input-group > .icon{
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  color: #bdc5cf;
}
</style>