import Api from "./api.js";

export default {
    tokenList(page = 1){
        return Api().get(`v3/businesses/api-token?page=${page}`);
    },
    addToken(payload){
        return Api().post("v3/businesses/api-token", payload);
    },
    removeToken(id){
        return Api().delete(`v3/businesses/api-token/${id}`);
    }
}
