import VuexPersistence  from 'vuex-persist';

export default [
  new VuexPersistence({
    key: 'peyk_space_information',
    storage: window.localStorage,
    reducer: (state) => {
      return {
        auth: state.auth,
        isActiveMenu: state.isActiveMenu
      }
    },
  }).plugin,
]
