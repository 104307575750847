<template>
  <div class="container-fluid">
    <div class="row set-max-height">
      <!-- ****************** left side bar ********************* -->
      <div class="col-md-6 order-md-first">
        <div class="row">
          <form class="col-md-8 offset-md-2 col-10 offset-1" @submit.prevent>
            <div class="header-form header-form-peyk">
              <img
                src="@/assets/images/icons/logo.png"
                class="mt-5"
                width="100"
              />

              <h1 class="mt-5 pt-5">Forgot Password</h1>
              <p class="my-3">Enter your Phone number</p>
            </div>

            <div class="body-form mb-5 pb-3 mt-4">
              <TelInput
                class="mt-5 mb-3"
                :form="form"
                :vuelidate="v$"
                field="mobile"
              />

              <SubmitButton class="d-grid gap-2 "
                :form="form"
                :vuelidate="v$"
                :canSubmit="true"
              >Send Verification Code</SubmitButton>

              <div class="link-login-signup mt-3">
                <router-link :to="{name: 'Login'}" class="v_link ps-2">Login</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- ****************** right side bar ********************* -->
      <div
        class="
          col-md-6
          px-0
          order-md-first order-first
          d-none d-sm-none d-md-block d-lg-block
          right-side
        "
      >
        <figure class="forgetpassword-page border-position-pics"></figure>
      </div>
    </div>
  </div>
</template>

<script>
import { ForgotPassword } from '@/forms/forgot-password';
import {
  SubmitButton,
  TelInput,
} from '@/components/forms';

export default {
  components: {
    SubmitButton,
    TelInput,
  },

  setup() {
    const form = ForgotPassword();
    const { v$ } = form;

    return { form, v$ };
  },

  methods: {
    updateUserRole(e) {
      this.form.user_role = e.target.checked ? 'business' : 'user';
    }
  },
};
</script>
