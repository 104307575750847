import { users } from '@/api';
import router from '@/router';
import { showMessage, isSuccessful } from '@/common/helpers';

const defaultState = {
  token: null,

  user: null,

  countdownSmsVerify: 120,

  registrationData: null,

  forgotPasswordData: null,
}

const state = () => ({...defaultState});

const mutations = {
  setUser(state, payload) {
    state.token = payload.access_token;
    state.user = payload;

    if (this.state.registrationData) {
      state.registrationData = null;
    }
  },

  setUserInformation(state, payload) {
    state.user.user_information = {
      ...state.user.user_information ,
      ...payload
    };
  },

  setToken(state, payload) {
    state.token = payload;
  },

  reset(state) {
    state = { ...defaultState };

    return state;
  },

  setForgotPasswordData(state, payload) {
    state.forgotPasswordData = payload;
  },

  setRegistrationData(state, payload) {
    state.registrationData = payload;
  },
}

const actions = {
  async login({ commit, dispatch, rootState }, payload) {

    const { data } = await users.login(payload);

    commit('setUser', data.data);

    showMessage(data);

    dispatch('getUserSettings', rootState.location, { root: true });
    // router.push({name: 'NewOrder'});

    return isSuccessful(data);
  },

  async logout({ commit }) {
    await users.logout();

    commit('setToken', null);

    localStorage.clear();

    router.push({ name: 'Login' });
  },

  async updateProfile({ commit }, payload) {
    const { data } = await users.changeProfile(payload);

    commit('setUserInformation', payload);

    showMessage(data);

    return isSuccessful(data);
  },

  async changeCurrentPassword(context, payload) {
    const { data } = await users.changeCurrentPassword(payload);

    showMessage(data);

    return isSuccessful(data);
  },

  async register({ commit }, payload) {
    const { data } = await users.register(payload);

    commit('setRegistrationData', payload);

    showMessage(data);

    return isSuccessful(data);
  },

  async verifyRegistrationMobile({ commit, state }, payload) {
    const { data } = await users.verifyRegistrationMobile({
      ...state.registrationData,
      ...payload,
    });

    commit('setUser', data.data);

    showMessage(data);

    return isSuccessful(data);
  },

  async resendRegisterVerificationCode(context, payload) {
    const { data } = await users.resendRegisterVerificationCode(payload);

    showMessage(data);

    return isSuccessful(data);
  },

  async forgotPassword({ commit }, payload) {
    const { data } = await users.forgotPassword(payload);

    commit('setForgotPasswordData', payload);

    showMessage(data);

    return isSuccessful(data)
      ? router.push({ name: 'ForgotPasswordVerification' })
      : false;
  },

  async verifyForgotPasswordMobile({ commit, state }, payload) {
    const { verification_code } = payload;

    const forgotPasswordData = {
      ...state.forgotPasswordData,
      verification_code,
    }

    const { data } = await users.verifyForgotPasswordMobile(forgotPasswordData);

    commit('setForgotPasswordData', forgotPasswordData);

    showMessage(data);

    return isSuccessful(data)
      ? router.push({ name: 'ResetPassword' })
      : false;
  },

  async resetPassword({ commit, state }, payload) {
    const { data } = await users.resetPassword({
      ...state.forgotPasswordData,
      ...payload,
    });

    commit('setUser', data.data);

    showMessage(data);

    return isSuccessful(data)
      ? router.push({ name: 'NewOrder' })
      : false;
  },

  async retryForgotPassword({ commit, state }) {
    const { data } = await users.retryForgotPassword(state.forgotPasswordData);

    commit('setUser', data.data);

    showMessage(data);

    return isSuccessful(data);
  }
}

const getters = {

  isLoggedIn: (state) => !!state.token,

  isBusinessUser: (state) => state.user?.user_information?.user_role === 'BUSINESS',

  business_name: (state) => state.user?.user_information?.business_name || 'Business Name', //fill default for prevent error in business form !!!

  first_name: (state) => state.user?.user_information?.first_name || '',

  last_name: (state) => state.user?.user_information?.last_name || '',

  fullname : (state) => state.user?.user_information?.first_name + ' ' +  state.user?.user_information?.last_name ,

  email: (state) => state.user?.user_information?.email,

  mobile :(state) => state.user?.user_information?.mobile,

  profile: (state, getters) => {
    const {
      first_name,
      last_name,
      email,
      mobile,
      business_name,
      isBusinessUser,
    } = getters;

    const businessProfile = isBusinessUser ? { business_name } : {}

    return {
      first_name,
      last_name,
      email,
      phone: mobile,
      ...businessProfile
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
