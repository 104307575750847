import Form from "@/services/form";

const data = {
  pickup: {
    lat: null,
    long: null,
  },

  dropoff_1: {
    lat: null,
    long: null,
  },

  // is_return: false,
  discount_code: null,
  discount_price: null,
};

const rules = {};

export default () => new Form(data, rules, "orders/getOrderPrice");
