<template>
  <div class="card mb-3">

    <div class="card-header bg-white " >
      <div class="row">
        <div class="col-md-4 col-sm-12 col-lg-4">

          <img v-if="driver?.avatar !== null" :src="driver?.avatar" class="avatar"/>
          <img v-else src="@/assets/images/icons/profile.png" class="avatar"/>

        </div>
        <div class="col-md-8 col-sm-12 col-lg-8">
          <h5 class="peyk-color pt-3 mb-0 ">
            {{ driver?.first_name }}&nbsp;{{ driver?.lastName }}
          </h5>
          <span class="text-muted">Driver</span>
        </div>
      </div>

    </div>

    <div class="card-body p-0">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="text-muted d-block">Phone Number</span>
          <span class="d-block item">{{  driver?.mobile }}</span>
        </li>
        <li class="list-group-item">
          <span class="text-muted d-block">Sender Name</span>
          <span class="d-block item">{{ senderName }}</span>
        </li>
        <li class="list-group-item">
          <span class="text-muted d-block">Return</span>
          <span class="d-block item">{{ returnTrip  ? 'YSE' : 'No'}}</span>
        </li>

        <li class="list-group-item d-flex py-3">
          <span class="text-muted text-start flex-fill">
            ETA
          </span>
          <span class="flex-fill item text-end">
            {{ eta }}
          </span>
        </li>
      </ul>
    </div>

  </div>

</template>

<script>
export default {
  name: "DriverInformation",
  props: ["driver" ,"senderName" ,"returnTrip" , "eta"],
  mounted() {
  }
}
</script>

<style scoped>
.item {
  color: #457C9F;
  font-size: 15px;
  font-family: BariolBold, serif;
}

.avatar{
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border:1px solid #aaa;
}
.peyk-color {
  color: #457C9F;
}
</style>