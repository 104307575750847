<template>
  <div class="container-fluid">
    <div class="row  set-max-height">

      <!-- ****************** left side bar ********************* -->
      <div class="col-md-6 order-md-first ">
        <div class="row ">
          <div class="col-md-8 offset-md-2 col-10 offset-1">

            <div class="header-form header-form-peyk pb-5">
              <img src="@/assets/images/icons/logo.png" class="mt-5" width="100">
              <h1 class="mt-5 pt-5">Verification Code</h1>
            </div>

            <h3 class="text-center text-muted"> {{ forgotPasswordData.mobile }}</h3>

            <form class="" @submit.prevent>
              <MaskInput class="col-12 p-0 mb-4"
                v-focus
                :form="form"
                :vuelidate="v$"
                field="verification_code"
                name="Verification Code"
              />

              <div class="link-login-signup text-center mb-2">
                  <template v-if="countDownHandle">
                    <div class="countdown">{{ countDownText }}</div>
                  </template>

                  <template v-else>
                    <a class="link-login-signup" href="#" @click.prevent="resendCode">Resend Code</a>
                  </template>
              </div>

              <SubmitButton class="d-grid gap-2 mt-3"
                :form="form"
                :vuelidate="v$"
                :canSubmit="true"
              />

              <div class="link-login-signup mt-3">
                <router-link :to="{name: 'Login'}" class="v_link ps-2">Login</router-link>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- ****************** right side bar ********************* -->
      <div class="col-md-6 px-0 order-md-first order-first d-none d-sm-none d-md-block d-lg-block right-side">
        <figure class="verification-page border-position-pics"></figure>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Verification } from "@/forms/forgot-password";
import { MaskInput, SubmitButton } from "@/components/forms";

export default {
  components: {
    MaskInput,
    SubmitButton,
  },

  data() {
    return {
      countDownHandle: null,
      counter: 0,
    };
  },

  computed: {
    ...mapState('auth', ['forgotPasswordData']),

    countDownText() {
      const minutes = Math.floor(this.counter / 60);
      const seconds = this.counter % 60;

      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  },

  setup() {
    const form = Verification();
    const { v$ } = form;

    return {
      form,
      v$,
    };
  },

  mounted() {
    this.enableCountDown();
  },

  methods: {
    ...mapActions('auth', ['retryForgotPassword']),

    resendCode() {
      this.retryForgotPassword();

      this.enableCountDown();
    },

    enableCountDown() {
      this.counter = 60;

      this.countDownHandle = setInterval(() => {
        this.counter--;

        if (! this.counter) {
          this.disableCountDown();
        }
      }, 1000);
    },

    disableCountDown() {
      clearInterval(this.countDownHandle);

      this.countDownHandle = null;
      this.counter = 0;
    }
  },

  unmounted() {
    this.disableCountDown();
  },
};
</script>
