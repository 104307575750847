<template>
  <div class="row">

    <div class="col-6">
    </div>

    <div class="col-6 d-flex justify-content-end align-items-center">
      <a href="#" class="peyk-link" @click.prevent="toggleShowPromoCode">
        <span v-if="! showPromoCode">
          <img src="@/assets/images/icons/plus.png" />
          Add Promo
        </span>

        <span v-else>
          <img src="@/assets/images/icons/minus.png" />
          Remove Promo
        </span>
      </a>
    </div>
    <div class="col-12" v-if="showPromoCode">
      <p class="mb-0 ms-2 mt-2 promo-txt">Promo Code</p>
      <div class="input-group py-2 add-promo">
        <input v-model="form.discount_code"
               type="text"
               class="form-control"
               placeholder="Enter your promo code"
        />
        <img src="@/assets/images/icons/promotion.png" />
        <button type="button" @click="submit">Confirm</button>
      </div>

      <InputError
          :form="form"
          :fieldVuelidate="v$"
          :errors="form.validator.errors"
          field="discount_code"
          name="Discount Code"
      />
    </div>
    <div class="col-12 mt-2 mb-2">
    </div>

    <div class="col-12" v-for="vehicle in vehicles" :key="vehicle.vehicle_id">
      <button type="button"
        class="btn-select active p-2"
        :class="{active: form.vehicle_id === vehicle.vehicle_id}"
        @click="selectVehicle(vehicle.vehicle_id,vehicle.discount_code )"
      >
        <div class="row">
          <div class="col-9 ">
          </div>

          <div class="col-12 text-center">
            <h4> <strong>Price : {{ vehicle.discount_price }} </strong> &nbsp; {{ vehicle.symbole}} </h4>
          </div>
        </div>

      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { PriceForm } from '@/forms/orders';
import { InputError } from '@/components/forms';

export default {
  name: "SelectVehicle",

  components: {
    InputError,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      showPromoCode: false,
    }
  },

  computed: {
    ...mapState('orders', ['vehicles'])
  },

  setup() {
    const form = PriceForm();
    const { v$ } = form;

    return {
      form,
      v$,
    }
  },

  async mounted() {
    this.assignForm();
    await this.submit();
  },

  methods: {
    ...mapActions('orders', ['clearVehicles']),

    async submit() {
      this.selectVehicle(null);
      await this.clearVehicles();
      console.log("form---vehicle--",this.form);
      await this.form.submit();
    },

    assignPoint(source, target) {
      source.lat = target.lat;
      source.long = target.long;
    },

    assignForm() {
      this.assignPoint(this.form.pickup, this.modelValue.addresses[0])
      this.assignPoint(this.form.dropoff_1, this.modelValue.addresses[1])
      this.assignPoint(this.form.dropoff_2, this.modelValue.addresses[2])
      this.assignPoint(this.form.dropoff_3, this.modelValue.addresses[3])

      const { vehicle_id, discount_code ,discount_price ,price } = this.modelValue;
      this.form.data = Object.assign(this.form.data, {
        vehicle_id,
        discount_code,
        discount_price,
        price,
      });
    },

    selectVehicle(vehicleId , price) {
      this.form.vehicle_id = vehicleId;
      this.form.discount_price = price
      const { vehicle_id, discount_code ,vehicle , quantity , discount_price  } = this.form.data;

      const data = {
        ...this.modelValue,
        vehicle_id,
        discount_code,
        vehicle,
        quantity,
        discount_price,
      };

      this.$emit('update:modelValue', data);
      this.$emit('changeVehicle', data);
    },

    toggleShowPromoCode() {
      this.showPromoCode = ! this.showPromoCode;

      if (! this.showPromoCode) {
        this.form.discount_code = null;
        console.log("form---vehicle--2--",this.form);
        this.form.submit();
      }
    }
  },
};
</script>
